import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Heading,
  Icon,
  Stack,
  Text,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import TeamCard from './TeamCard';
import { Link } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';

function Team() {
  const [data, setData] = useState(null);
  const [selectedService, setSelectedService] = useState('allMember');
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    // const storeId = localStorage.getItem('storeId');
    setIsLoading(true);
    try {
      const response = await GET(`/store/team/getAllUsers`);
      console.log(response);
      if (response.status === 'success') {
        setData(response.data);
        setIsLoading(false);
      } else {
        console.log(response);
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Stack px={6} py={4}>
      <Stack
        mb={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Stack display={'flex'} flexDirection={'column'}>
          <Heading fontSize={'30px'} fontWeight={'600'}>
            Team Members
          </Heading>
          {/* <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the latest update...Check Now!
          </Text> */}
        </Stack>
        <Button
          padding={'10px 50px'}
          bg={
            'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
          }
          as={Link}
          to={'/dashboard/add-new-member'}
          color={'#fff'}
          fontWeight={'500'}
          lineHeight={'inherit'}
          borderRadius={12}
          _hover={{
            color: '#000',
          }}
        >
          Add New Member
        </Button>
      </Stack>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#47A046" />
        </Box>
      ) : (
        <Stack>
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={4}
            flexWrap={'wrap'}
          >
            <Button
              border={
                selectedService === 'allMember'
                  ? '2px solid #EAA13E'
                  : '2px solid #07d57c'
              }
              padding={'10px 25px'}
              bg={
                selectedService === 'allMember'
                  ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                  : 'transparent'
              }
              width={'max-content'}
              onClick={() => setSelectedService('allMember')}
            >
              All Team Members
            </Button>
            <Button
              border={
                selectedService === 'blockMember'
                  ? '2px solid #EAA13E'
                  : '2px solid #07d57c'
              }
              padding={'10px 25px'}
              bg={
                selectedService === 'blockMember'
                  ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                  : 'transparent'
              }
              width={'max-content'}
              onClick={() => setSelectedService('blockMember')}
            >
              Block Team Members
            </Button>
          </Stack>

          <TeamCard
            selectedService={selectedService}
            data={data?.[selectedService]}
            fetchData={fetchData}
          />
        </Stack>
      )}
    </Stack>
  );
}

export default Team;
