import {
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Link,
  Box,
  Image,
  Text,
  Tag,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';

import { baseURL, imgUrl } from '../../utilities/config';
import { useSelector } from 'react-redux';
import { selectWebsiteData } from '../../reducers/slices/website.slice';
import Header from '../../components/Website/Header';
import MiniBanner from '../../components/Website/banner/MiniBanner';
import Footer from '../../components/Website/Footer'


export default function OurDiscounts() {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    }, []);

    const websiteData = useSelector(selectWebsiteData);
    const discountlist = useMemo(() => {
      return websiteData.discount;
    }, [websiteData.discount]);
  

  return (
    <>
     <Header/>
    <MiniBanner title={'Our Discounts'} breadtitle={'Our Discounts'}/>
    <Stack>
        <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
          <Stack px={12} bg={'#fff'} py={12} borderRadius={12}>
          <Box
            mb={6}
            display={'flex'}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
            alignItems={'center'}
            className="mh-2 "
            gap={3}
          >
            <Heading as={'h2'}>Don't Miss our Discounts</Heading>
           
          </Box>
          <SimpleGrid columns={{ base: '2', xl: '3', '2xl': '6' }} spacing={2}>
            {discountlist?.length > 0 &&
              discountlist.map((v, k) => {
                return (
                  <Stack
                    as={ReactLink}
                    to={`/discountoffer/${v._id}`}
                    key={k}
                    bg={'#fff'}
                    borderRadius={12}
                    overflow={'hidden'}
                    boxShadow="lg"
                  >
                    <Stack>
                    <Image height={'144px'} objectFit={'cover'} src={`${imgUrl}/${v.image}`} />
                    </Stack>

                    <Stack
                      className="mh-3"
                      mt={'0 !important'}
                      px={4}
                      py={4}
                      spacing={2}
                    >
                      <Tag
                        width={'max-content'}
                        mt={'-32px'}
                        mb={2}
                        bg={
                          'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                        }
                        py={2}
                        px={2}
                        borderRadius={12}
                        color={'#fff'}
                        fontWeight={600}
                        fontSize={'12px'}
                      >
                        {v.discount}% Discount
                      </Tag>
                      <Heading noOfLines={[1,2]}  as={'h3'}>{v.name}</Heading>
                      <Text noOfLines={[1,2]} fontSize={'13px'} color={'#212121'}>
                        {v.description}
                      </Text>
                    </Stack>
                  </Stack>
                );
              })}
          </SimpleGrid>
            </Stack>
            </Container>
            </Stack>
  
    
    <Footer/>
    </>
  )
}
