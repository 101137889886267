import {
  Avatar,
  Box,
  Button,
  Heading,
  Stack,
  Text,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import PurchasedHistory from './PurchasedHistory';
import Bounus from './Bounus';
import Activity from './Activity';
import { useNavigate, useParams } from 'react-router-dom';
import { DELETE, GET, POST } from '../../../utilities/ApiProvider';
import moment from 'moment';
import { imgUrl } from '../../../utilities/config';

export default function UserDetail() {
  const navigate = useNavigate();
  const toast = useToast();
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [selectedService, setSelectedService] = useState('products');
  const [Orderdetails, setOrderdetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const storeId = localStorage.getItem('storeId');
  useEffect(() => {
    fetchOrderDetails();
  }, []);

  async function fetchOrderDetails() {
    setIsLoading(true);
    try {
      const response = await GET(`/users/singleuser/${id}`);
      console.log('user Details', response);

      if (response.status === 'success') {
        setOrderdetails(response.data);
        setIsLoading(false);
      } else {
        console.log('Error while fetching products');
      }
    } catch (error) {
      console.log(error, 'error while fetching products');
    }
  }

  const handleblock = async userId => {
    setIsRemoveLoading(true);
    try {
      const response = await POST(`/users/blockuser/${userId}?action=block`);

      if (response.status === 'success') {
        toast({
          description: response?.message,
          status: response?.status,
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        navigate('/dashboard/users');
      } else {
        toast({
          description: response?.message,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleunblock = async userId => {
    setIsRemoveLoading(true);
    try {
      const response = await POST(`/users/blockuser/${userId}?action=unblock`);

      if (response.status === 'success') {
        toast({
          description: response?.message,
          status: response?.status,
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        navigate('/dashboard/users');
      } else {
        toast({
          description: response?.message,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Stack gap={6} pb={6}>
      <Stack>
        <Heading fontSize={'30px'} fontWeight={'700'}>
          Users Details
        </Heading>
        {/* <Text fontSize={'15px'} color={'#1A1B1A'} fontWeight={'400'}>
          Here is the all user details...Check Now!
        </Text> */}
      </Stack>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#47A046" />
        </Box>
      ) : (
        <Stack gap={4}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={24}
            flexWrap={'wrap'}
          >
            <Box display={'flex'} gap={2} alignItems={'center'}>
              <Avatar
                size="lg"
                name="----"
                src={
                  `${imgUrl}${Orderdetails?.profile_picture}` ||
                  'https://bit.ly/dan-abramov'
                }
              />
              <Box>
                <Heading color={'#007341'} fontSize={'20px'}>
                  {Orderdetails?.fullName || '-------'}
                </Heading>
                <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                  {Orderdetails?.role || '-------'}
                </Text>
              </Box>
            </Box>
            <Box>
              <Heading color={'#007341'} fontSize={'16px'}>
                Email Address
              </Heading>
              <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                {Orderdetails?.email || '------------'}
              </Text>
            </Box>
            <Box>
              <Heading color={'#007341'} fontSize={'16px'}>
                Phone No.
              </Heading>
              <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                {Orderdetails?.phone || '+92 ------------'}
              </Text>
            </Box>
            <Box>
              <Heading color={'#007341'} fontSize={'16px'}>
                Member Since
              </Heading>
              <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                {moment(Orderdetails?.createdAt).format('DD-MMM-YYYY') ||
                  '-/--/----'}
              </Text>
            </Box>
            {Orderdetails?.blocked ? (
              <Button
                onClick={() => handleunblock(Orderdetails?._id)}
                background={'#E94646'}
                w="135px"
                h="40px"
                borderRadius={'9px'}
                color={'#fff'}
              >
                Unblock
              </Button>
            ) : (
              <Button
                onClick={() => handleblock(Orderdetails?._id)}
                background={'#E94646'}
                w="135px"
                h="40px"
                borderRadius={'9px'}
                color={'#fff'}
              >
                Block
              </Button>
            )}
          </Stack>
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={4}
            flexWrap={'wrap'}
          >
            <Button
              border={
                selectedService === 'products'
                  ? '2px solid #EAA13E'
                  : '2px solid #07d57c'
              }
              padding={'10px 25px'}
              bg={
                selectedService === 'products'
                  ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                  : 'transparent'
              }
              width={'max-content'}
              onClick={() => setSelectedService('products')}
            >
              Purchased History
            </Button>
            <Button
              border={
                selectedService === 'card'
                  ? '2px solid #EAA13E'
                  : '2px solid #07d57c'
              }
              padding={'10px 25px'}
              bg={
                selectedService === 'card'
                  ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                  : 'transparent'
              }
              width={'max-content'}
              onClick={() => setSelectedService('card')}
            >
              Bonus Card
            </Button>
            <Button
              border={
                selectedService === 'activityLogs'
                  ? '2px solid #EAA13E'
                  : '2px solid #07d57c'
              }
              padding={'10px 25px'}
              bg={
                selectedService === 'activityLogs'
                  ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                  : 'transparent'
              }
              width={'max-content'}
              onClick={() => setSelectedService('activityLogs')}
            >
              Activity Logs
            </Button>
          </Stack>

          {selectedService === 'products' ? (
            <PurchasedHistory data={Orderdetails?.[selectedService]} />
          ) : selectedService === 'card' ? (
            <Bounus data={Orderdetails?.[selectedService]} />
          ) : (
            <Activity data={Orderdetails?.[selectedService]} />
          )}
        </Stack>
      )}
    </Stack>
  );
}
