import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Heading,
  FormControl,
  Radio,
  Icon,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Image,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { POST } from '../../../utilities/ApiProvider';
function AddOffer() {
  const toast = useToast();
  const items = [
    {
      id: 1,
      name: 'Spring Onions 1 Bunch',
      price: 25.0,
      image: 'https://via.placeholder.com/50', // replace with actual image URL
      rating: 5.0,
    },
    {
      id: 2,
      name: 'Spring Onions 1 Bunch',
      price: 25.0,
      image: 'https://via.placeholder.com/50', // replace with actual image URL
      rating: 5.0,
    },
    {
      id: 3,
      name: 'Spring Onions 1 Bunch',
      price: 25.0,
      image: 'https://via.placeholder.com/50', // replace with actual image URL
      rating: 5.0,
    },
  ];
  const [loading, setLoading] = useState(false);
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData(e.target);
      formData.append('type', 'percentage');
      formData.append('storeId', '66792cc177d1c268a6bf34af');
      if (
        Array.from(formData.entries())?.some(([key, value]) => value === '')
      ) {
        console.log(Array.from(formData.entries()));
        setLoading(false);
        toast({
          title: 'Please fill all the fields',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        return;
      }
      const response = await POST('/admin/coupon', formData);
      console.log(response);

      if (response.status === 'success') {
        console.log(response.data);
        setLoading(false);
        toast({
          title: 'Coupon added successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        e.target.reset();
      } else {
        setLoading(false);
        toast({
          title: `${response.message}`,
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <Stack>
      <Stack display={'flex'} flexDirection={'column'}>
        <Heading fontSize={'30px'} fontWeight={'600'}>
          Add New Offer
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the latest update...Check Now!
        </Text>
      </Stack>

      <form onSubmit={handleSubmit}>
        <Stack direction={'row'} gap={6} justifyContent={'space-between'}>
          <Stack width={'50%'} gap={3}>
            <FormControl>
              <FormLabel
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'30px'}
              >
                Coupon Name
              </FormLabel>
              <Input
                background={'#F3F3F3'}
                border={'1px solid #B4B4B4'}
                py={6}
                name="name"
                type="text"
                placeholder="Enter Coupon Name"
              />
            </FormControl>
            <FormControl>
              <FormLabel
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'30px'}
              >
                Coupon Code
              </FormLabel>
              <Input
                background={'#F3F3F3'}
                border={'1px solid #B4B4B4'}
                py={6}
                name="couponCode"
                type="text"
                placeholder="Enter Coupon Code"
              />
            </FormControl>
            <FormControl>
              <FormLabel
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'30px'}
              >
                Discount (%)
              </FormLabel>
              <Input
                background={'#F3F3F3'}
                border={'1px solid #B4B4B4'}
                py={6}
                type="number"
                name="discount"
                placeholder="Enter Discount Percentage"
              />
            </FormControl>
            <Box display={'flex'} gap={3}>
              <FormControl>
                <FormLabel
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'30px'}
                >
                  Choose Date
                </FormLabel>
                <Input
                  background={'#F3F3F3'}
                  border={'1px solid #B4B4B4'}
                  py={6}
                  name="start"
                  type="date"
                  placeholder="Enter Your Name"
                />
              </FormControl>

              <FormControl>
                <FormLabel
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'30px'}
                >
                  End Time
                </FormLabel>
                <Input
                  background={'#F3F3F3'}
                  border={'1px solid #B4B4B4'}
                  py={6}
                  type="date"
                  name="end"
                  placeholder="Enter Your Name"
                />
              </FormControl>
            </Box>
            <FormControl>
              <FormLabel
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'30px'}
              >
                No. of Users
              </FormLabel>
              <Input
                background={'#F3F3F3'}
                border={'1px solid #B4B4B4'}
                py={6}
                type="number"
                name="limit"
                placeholder="Enter No. of Users"
              />
            </FormControl>

            <Box my={4}>
              <Button
                mb={2}
                type="submit"
                isLoading={loading}
                bg={
                  'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
                }
                w="176px"
                h="49px"
                borderRadius={'9px'}
              >
                Continue
              </Button>
            </Box>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}

export default AddOffer;
