import {
  Box,
  Container,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Storelocation } from '../../../assets/data/Data';
import { GET } from '../../../utilities/ApiProvider';
import map from '../../../assets/images/website/map.jpg';
import location from '../../../assets/images/website/location.svg';
import { useDispatch } from 'react-redux';
import { setWebsiteData } from '../../../reducers/slices/website.slice';
import { useNavigate } from 'react-router-dom';

export default function StoreLocation() {
  const [mapstore, setmapstore] = useState(Storelocation);
  const [data, setData] = useState(null);
  const [id, setId] = useState('');
  const navigate = useNavigate();
  const storeId = localStorage.getItem('storeId');
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchArea() {
      try {
        const response = await GET('/store');
        if (response.status === 'success') {
          setData(response.data);
        } else {
          console.log('Error while fetching area');
        }
      } catch (error) {
        console.log('error while fetching area', error);
      }
    }
    fetchArea();
  }, []);
  async function getHomeData(selectedArea) {
    try {
      const response = await GET(`/users/webHome?storeId=${selectedArea}`);
      dispatch(setWebsiteData(response));
      navigate('/');
    } catch (error) {
      console.error('An error occurred while fetching user data:', error);
    }
  }
  const handleClick = id => {
    setId(id);
    localStorage.setItem('storeId', id);
    getHomeData(id);
  };
  return (
    <Stack>
      <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
        <Stack px={12} bg={'#fff'} py={12} borderRadius={12}>
          {!data ? (
            <Stack
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              height={'50vh'}
            >
              <Spinner />
            </Stack>
          ) : (
            <SimpleGrid
              columns={{ base: '1', md: '2', xl: '2', '2xl': '2' }}
              spacing={10}
            >
              {data?.length > 0 &&
                data.map((v, k) => {
                  return (
                    <Stack
                      bg="rgba(0, 0, 0, 0.04)"
                      px={4}
                      py={8}
                      borderRadius={12}
                      alignItems={'center'}
                      gap={3}
                      cursor={'pointer'}
                      border={storeId === v?._id && '3px solid orange'}
                      onClick={() => handleClick(v?._id)}
                    >
                      <Image w={'80px'} src={location} />
                      <Text fontSize={'16px'} fontWeight={600}>
                        {v.area} {v.state}
                      </Text>
                      <Stack width={'100%'}>
                        {/* <iframe
                          width="100%"
                          height="600"
                          frameborder="0"
                          scrolling="no"
                          marginheight="0"
                          marginwidth="0"
                          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=28C%20Khayaban-e-Bahria,%20D.H.A.%20Phase%204%20,%20Karachi+(Ajwa%20Supermart)&amp;t=&amp;z=19&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        >
                          <a href="https://www.gps.ie/">gps tracker sport</a>
                        </iframe> */}
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.31511882983!2d67.0611632867755!3d24.818894990096098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33c5c673db89d%3A0xc5fc85405d39eb18!2sAjwa%20Supermarket!5e0!3m2!1sen!2s!4v1722324447124!5m2!1sen!2s"
                          // width="100%"
                          // height="600"
                          // frameborder="0"
                          // allowfullscreen=""
                          // loading="lazy"
                          // referrerpolicy="no-referrer-when-downgrade"
                          // new//
                          width="100%"
                          height="600"
                          frameborder="0"
                          scrolling="no"
                          marginheight="0"
                          marginwidth="0"
                        ></iframe>
                      </Stack>
                    </Stack>
                  );
                })}
            </SimpleGrid>
          )}
        </Stack>
      </Container>
    </Stack>
  );
}
