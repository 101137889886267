// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   Grid,
//   HStack,
//   Heading,
//   Avatar,
//   Icon,
//   Stack,
//   Input,
//   Text,
//   FormLabel,
//   FormControl,
//   Checkbox,
//   VStack,
//   Select,
//   useToast,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   useDisclosure,
// } from '@chakra-ui/react';
// import { AiOutlineLock } from 'react-icons/ai';
// import { BsEyeSlash, BsEye } from 'react-icons/bs';
// import { LuUser2, LuPhone } from 'react-icons/lu';
// import ModalWrapper from '../../Modal';
// import { imgUrl } from '../../../utilities/config';
// import { GET, POST, PUT } from '../../../utilities/ApiProvider';
// import { useSelector } from 'react-redux';
// import { GoUpload, GoMail } from 'react-icons/go';

// function Account({ datas, storeId, id }) {
//   const { onClose, isOpen, onOpen } = useDisclosure();
//   const imageUploader = React.useRef(null);
//   const [data, setData] = useState(datas);
//   const [uploadedImage, setUploadedImage] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [showpass, setshowpass] = useState(false);
//   const [stores, setStores] = useState([]);
//   const toast = useToast();
//   const [pass, setPass] = useState({
//     password: '',
//     confirmPassword: '',
//   });

//   const handleImageUpload = e => {
//     const [file] = e.target.files;
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = event => {
//         setUploadedImage(event.target.result);
//       };
//       reader.readAsDataURL(file);
//     }
//   };
//   async function getStores() {
//     try {
//       const response = await GET(`/store`);
//       if (response.status === 'success') {
//         console.log(response.data);
//         setStores(response.data);
//       } else {
//         console.log(response);
//         setStores([]);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   }
//   useEffect(() => {
//     getStores();
//   }, []);

//   const user = useSelector(state => state.user.value);
//   const handleSubmit = async e => {
//     e.preventDefault();
//     console.log('hello');
//     try {
//       const formData = new FormData(e.target);

//       let response = await PUT(`/store/team/update/${id}`, formData, {
//         Authorization: `Bearer ${user?.verificationToken}`,
//       });

//       console.log(response);
//       if (response.status === 'success') {
//         setData(response.data?.[0]);
//         toast({
//           description: 'Profile updated successfully',
//           status: 'success',
//           position: 'top-right',
//           duration: 5000,
//           isClosable: true,
//         });
//       } else {
//         toast({
//           description: response?.message,
//           status: 'error',
//           position: 'top-right',
//           duration: 5000,
//           isClosable: true,
//         });
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const updatePass = async () => {
//     setIsLoading(true);
//     try {
//       let res = await PUT(`/users/newPassword`, pass, {
//         Authorization: `Bearer ${user?.verificationToken}`,
//       });
//       console.log('update pass ', res);
//       toast({
//         description: res.message,
//         status: res.status,
//         position: 'top-right',
//         duration: 3000,
//       });
//       onClose();
//     } catch (err) {
//       toast({
//         description: err.message,
//         status: 'success',
//         position: 'top-right',
//         duration: 1000,
//       });
//     }
//     setIsLoading(false);
//   };

//   return (
//     <>
//       <Text fontSize={'18px'} fontWeight={500}>
//         Account Settings
//       </Text>
//       <form onSubmit={handleSubmit}>
//         <Stack>
//           <HStack gap={2}>
//             <Input
//               type="file"
//               name="profile_picture"
//               accept="image/*"
//               onChange={handleImageUpload}
//               ref={imageUploader}
//               display="none"
//             />
//             <Avatar
//               w="121px"
//               h="121px"
//               name="Dan Abrahmov"
//               src={uploadedImage || `${imgUrl}${data?.profile_picture}`}
//             />
//             <VStack>
//               <Button
//                 bg={
//                   'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
//                 }
//                 color={'#fff'}
//                 fontWeight={'500'}
//                 lineHeight={'inherit'}
//                 borderRadius={12}
//                 w="142px"
//                 _hover={{
//                   color: '#000',
//                 }}
//                 onClick={() => imageUploader.current.click()}
//               >
//                 Change Photo
//               </Button>
//               {/* <Button
//                 border={'2px solid #07d57c'}
//                 bg={'transparent'}
//                 borderRadius={12}
//                 w="142px"
//                 _hover={{
//                   color: '#000',
//                 }}
//               >
//                 Remove Photo
//               </Button> */}
//             </VStack>
//           </HStack>
//           <Stack width={'100%'} gap={6} pt={4}>
//             <Box display={'flex'} gap={3}>
//               <FormControl>
//                 <FormLabel
//                   fontSize={'16px'}
//                   fontWeight={'500'}
//                   lineHeight={'30px'}
//                 >
//                   Full Name
//                 </FormLabel>
//                 <Box className="Ifiled">
//                   <Icon fontSize={'25px'} color={'#949494'} as={LuUser2} />
//                   <Input
//                     defaultValue={data?.fullName}
//                     background={'#F3F3F3'}
//                     border={'1px solid #B4B4B4'}
//                     py={6}
//                     name="fullName"
//                     type="text"
//                     placeholder="Enter Your Name"
//                   />
//                 </Box>
//               </FormControl>

//               <FormControl>
//                 <FormLabel
//                   fontSize={'16px'}
//                   fontWeight={'500'}
//                   lineHeight={'30px'}
//                 >
//                   Email Address
//                 </FormLabel>
//                 <Box className="Ifiled">
//                   <Icon fontSize={'25px'} color={'#949494'} as={GoMail} />
//                   <Input
//                     defaultValue={data?.email}
//                     background={'#F3F3F3'}
//                     border={'1px solid #B4B4B4'}
//                     py={6}
//                     type="text"
//                     placeholder="Enter Your Name"
//                     readOnly
//                     cursor={'not-allowed'}
//                   />
//                 </Box>
//               </FormControl>
//             </Box>
//             <Box display={'flex'} gap={3}>
//               <FormControl>
//                 <FormLabel
//                   fontSize={'16px'}
//                   fontWeight={'500'}
//                   lineHeight={'30px'}
//                 >
//                   Phone No.
//                 </FormLabel>
//                 <Box className="Ifiled">
//                   <Icon fontSize={'25px'} color={'#949494'} as={LuPhone} />
//                   <Input
//                     defaultValue={data?.phone}
//                     background={'#F3F3F3'}
//                     border={'1px solid #B4B4B4'}
//                     py={6}
//                     name="phone"
//                     type="text"
//                     placeholder="Enter Your Name"
//                   />
//                 </Box>
//               </FormControl>
//               <Stack
//                 w="100%"
//                 display={'flex'}
//                 justifyContent={'end'}
//                 alignItems={'end'}
//               >
//                 <Button
//                   w="max-content"
//                   border={'2px solid #1A1B1A'}
//                   bg={'transparent'}
//                   borderRadius={8}
//                   onClick={onOpen}
//                   _hover={{
//                     color: '#000',
//                   }}
//                 >
//                   Change Password
//                 </Button>
//               </Stack>
//             </Box>

//             <Button
//               padding={'10px 50px'}
//               bg={
//                 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
//               }
//               color={'#fff'}
//               fontWeight={'500'}
//               lineHeight={'inherit'}
//               borderRadius={12}
//               type="submit"
//               w="176px"
//               _hover={{
//                 color: '#000',
//               }}
//             >
//               Save Changes
//             </Button>
//           </Stack>
//         </Stack>
//       </form>
//       <Modal isOpen={isOpen} onClose={onClose} size="2xl">
//         <ModalOverlay />
//         <ModalContent
//           bgSize="cover"
//           bgColor={'#fff'}
//           bgPos="center"
//           p="20px 40px"
//         >
//           <ModalHeader
//             fontWeight="600"
//             fontSize={'24px'}
//             color={'#111'}
//             textAlign="center"
//           >
//             Change Password
//           </ModalHeader>
//           <ModalBody>
//             <FormLabel color={'#111'} mt="20px !important" fontWeight="600">
//               New Password
//             </FormLabel>
//             <Box className="Ifiled">
//               <Icon fontSize={'25px'} color={'#2BAF59'} as={AiOutlineLock} />
//               <Input
//                 type={showpass ? 'text' : 'password'}
//                 placeholder="Enter here"
//                 value={pass.password}
//                 onChange={e =>
//                   setPass({
//                     ...pass,
//                     password: e.target.value,
//                   })
//                 }
//               />
//               <Button onClick={() => setshowpass(!showpass)}>
//                 <Icon as={showpass ? BsEye : BsEyeSlash} />
//               </Button>
//             </Box>
//             <FormLabel mt="20px !important" color={'#111'} fontWeight="600">
//               Re-type Password
//             </FormLabel>
//             <Box className="Ifiled">
//               <Icon fontSize={'25px'} color={'#2BAF59'} as={AiOutlineLock} />
//               <Input
//                 type={showpass ? 'text' : 'password'}
//                 placeholder="Enter here"
//                 value={pass.confirmPassword}
//                 onChange={e =>
//                   setPass({
//                     ...pass,
//                     confirmPassword: e.target.value,
//                   })
//                 }
//               />
//               <Button onClick={() => setshowpass(!showpass)}>
//                 <Icon as={showpass ? BsEye : BsEyeSlash} />
//               </Button>
//             </Box>
//           </ModalBody>
//           <ModalFooter justifyContent="center">
//             <Stack className="btn">
//               <Button
//                 onClick={updatePass}
//                 isLoading={isLoading}
//                 bg={
//                   'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
//                 }
//                 fontSize={{ base: '12px', xl: '14px' }}
//                 fontWeight={500}
//                 color={'#fff'}
//                 width={'max-content'}
//                 px={'40px'}
//                 py={'24px'}
//                 gap={2}
//                 transition={'.3s'}
//                 h={{ base: '35px', xl: '45px' }}
//                 _hover={{
//                   boxShadow: '0px 7px 20px 0px #0000003b',
//                 }}
//               >
//                 <Text as={'span'}>Save</Text>
//               </Button>
//             </Stack>
//           </ModalFooter>
//         </ModalContent>
//       </Modal>
//     </>
//   );
// }

// export default Account;
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  HStack,
  Heading,
  Avatar,
  Icon,
  Stack,
  Input,
  Text,
  FormLabel,
  FormControl,
  Checkbox,
  VStack,
  Select,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';
import { AiOutlineLock } from 'react-icons/ai';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import { LuUser2, LuPhone } from 'react-icons/lu';
import ModalWrapper from '../../Modal';
import { imgUrl } from '../../../utilities/config';
import { GET, POST, PUT } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { GoUpload, GoMail } from 'react-icons/go';

function Account({ data, storeId, id, getMemberDetails }) {
  console.log(data);
  const { onClose, isOpen, onOpen } = useDisclosure();
  const imageUploader = React.useRef(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showpass, setshowpass] = useState(false);
  const [stores, setStores] = useState([]);
  const toast = useToast();
  const [pass, setPass] = useState({
    password: '',
    confirmPassword: '',
  });

  const handleImageUpload = e => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      reader.onload = event => {
        setUploadedImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  async function getStores() {
    try {
      const response = await GET(`/store`);
      if (response.status === 'success') {
        console.log(response.data);
        setStores(response.data);
      } else {
        console.log(response);
        setStores([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getStores();
  }, []);

  const user = useSelector(state => state.user.value);
  const handleSubmit = async e => {
    e.preventDefault();
    console.log('hello');
    try {
      const formData = new FormData(e.target);

      let response = await PUT(`/store/team/update/${id}`, formData, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });

      console.log(response);
      if (response.status === 'success') {
        toast({
          description: 'Profile updated successfully',
          status: 'success',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
        getMemberDetails();
      } else {
        toast({
          description: response?.message,
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatePass = async () => {
    setIsLoading(true);
    try {
      let res = await PUT(`/users/newPassword`, pass, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log('update pass ', res);
      toast({
        description: res.message,
        status: res.status,
        position: 'top-right',
        duration: 3000,
      });
      onClose();
    } catch (err) {
      toast({
        description: err.message,
        status: 'success',
        position: 'top-right',
        duration: 1000,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Text fontSize={'18px'} fontWeight={500}>
        Account Settings
      </Text>
      <form onSubmit={handleSubmit}>
        <Stack>
          <HStack gap={2}>
            <Input
              type="file"
              name="profile_picture"
              accept="image/*"
              onChange={handleImageUpload}
              ref={imageUploader}
              display="none"
            />
            <Avatar
              w="121px"
              h="121px"
              name="Dan Abrahmov"
              src={uploadedImage || `${imgUrl}${data?.profile_picture}`}
            />
            <VStack>
              <Button
                bg={
                  'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
                }
                color={'#fff'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                w="142px"
                _hover={{
                  color: '#000',
                }}
                onClick={() => imageUploader.current.click()}
              >
                Change Photo
              </Button>
              {/* <Button
                border={'2px solid #07d57c'}
                bg={'transparent'}
                borderRadius={12}
                w="142px"
                _hover={{
                  color: '#000',
                }}
              >
                Remove Photo
              </Button> */}
            </VStack>
          </HStack>
          <Stack width={'100%'} gap={6} pt={4}>
            <Box display={'flex'} gap={3}>
              <FormControl>
                <FormLabel
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'30px'}
                >
                  Full Name
                </FormLabel>
                <Box className="Ifiled">
                  <Icon fontSize={'25px'} color={'#949494'} as={LuUser2} />
                  <Input
                    defaultValue={data?.fullName}
                    background={'#F3F3F3'}
                    border={'1px solid #B4B4B4'}
                    py={6}
                    name="fullName"
                    type="text"
                    placeholder="Enter Your Name"
                  />
                </Box>
              </FormControl>

              <FormControl>
                <FormLabel
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'30px'}
                >
                  Email Address
                </FormLabel>
                <Box className="Ifiled">
                  <Icon fontSize={'25px'} color={'#949494'} as={GoMail} />
                  <Input
                    defaultValue={data?.email}
                    background={'#F3F3F3'}
                    border={'1px solid #B4B4B4'}
                    py={6}
                    type="email"
                    placeholder="Enter Your Name"
                    readOnly
                    cursor={'not-allowed'}
                  />
                </Box>
              </FormControl>
            </Box>
            <Box display={'flex'} gap={3}>
              <FormControl>
                <FormLabel
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'30px'}
                >
                  Phone No.
                </FormLabel>
                <Box className="Ifiled">
                  <Icon fontSize={'25px'} color={'#949494'} as={LuPhone} />
                  <Input
                    defaultValue={data?.phone}
                    background={'#F3F3F3'}
                    border={'1px solid #B4B4B4'}
                    py={6}
                    name="phone"
                    type="number"
                    placeholder="Enter Your Name"
                  />
                </Box>
              </FormControl>
              <Stack
                w="100%"
                display={'flex'}
                justifyContent={'end'}
                alignItems={'end'}
              >
                <Button
                  w="max-content"
                  border={'2px solid #1A1B1A'}
                  bg={'transparent'}
                  borderRadius={8}
                  onClick={onOpen}
                  _hover={{
                    color: '#000',
                  }}
                >
                  Change Password
                </Button>
              </Stack>
            </Box>

            <Button
              padding={'10px 50px'}
              bg={
                'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              type="submit"
              w="176px"
              _hover={{
                color: '#000',
              }}
            >
              Save Changes
            </Button>
          </Stack>
        </Stack>
      </form>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent
          bgSize="cover"
          bgColor={'#fff'}
          bgPos="center"
          p="20px 40px"
        >
          <ModalHeader
            fontWeight="600"
            fontSize={'24px'}
            color={'#111'}
            textAlign="center"
          >
            Change Password
          </ModalHeader>
          <ModalBody>
            <FormLabel color={'#111'} mt="20px !important" fontWeight="600">
              New Password
            </FormLabel>
            <Box className="Ifiled">
              <Icon fontSize={'25px'} color={'#2BAF59'} as={AiOutlineLock} />
              <Input
                type={showpass ? 'text' : 'password'}
                placeholder="Enter here"
                value={pass.password}
                onChange={e =>
                  setPass({
                    ...pass,
                    password: e.target.value,
                  })
                }
              />
              <Button onClick={() => setshowpass(!showpass)}>
                <Icon as={showpass ? BsEye : BsEyeSlash} />
              </Button>
            </Box>
            <FormLabel mt="20px !important" color={'#111'} fontWeight="600">
              Re-type Password
            </FormLabel>
            <Box className="Ifiled">
              <Icon fontSize={'25px'} color={'#2BAF59'} as={AiOutlineLock} />
              <Input
                type={showpass ? 'text' : 'password'}
                placeholder="Enter here"
                value={pass.confirmPassword}
                onChange={e =>
                  setPass({
                    ...pass,
                    confirmPassword: e.target.value,
                  })
                }
              />
              <Button onClick={() => setshowpass(!showpass)}>
                <Icon as={showpass ? BsEye : BsEyeSlash} />
              </Button>
            </Box>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Stack className="btn">
              <Button
                onClick={updatePass}
                isLoading={isLoading}
                bg={
                  'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                }
                fontSize={{ base: '12px', xl: '14px' }}
                fontWeight={500}
                color={'#fff'}
                width={'max-content'}
                px={'40px'}
                py={'24px'}
                gap={2}
                transition={'.3s'}
                h={{ base: '35px', xl: '45px' }}
                _hover={{
                  boxShadow: '0px 7px 20px 0px #0000003b',
                }}
              >
                <Text as={'span'}>Save</Text>
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Account;
