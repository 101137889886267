import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Stack,
  Link,
  useToast,
  Box,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import { GoMail } from 'react-icons/go';
import { jwtDecode } from 'jwt-decode';

import { AiOutlineLock } from 'react-icons/ai';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { POST } from '../../../utilities/ApiProvider';
import { useDispatch } from 'react-redux';
import { loadUser } from '../../../reducers/useReducers';
import { GoogleLogin } from '@react-oauth/google';

export default function LoginFrom() {
  const [showpass, setshowpass] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const formData = new FormData(e.target);
      
      // Get the form fields
      const email = formData.get('email');
      const password = formData.get('password');
  
      // Array to store missing fields
      const missingFields = [];
  
      // Check if email and password are provided
      if (!email) missingFields.push('Email');
      if (!password) missingFields.push('Password');
  
      // If there are missing fields, show a toast with a detailed message
      if (missingFields.length > 0) {
        setLoading(false);
        toast({
          description: `Please fill out the following fields: ${missingFields.join(', ')}`,
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      const response = await POST('/users/login', formData);
      console.log('Login Response', response);
      
      if (response.status === 'success' && response.data) {
        setLoading(false);
        localStorage.setItem('ajwaUser', JSON.stringify(response.data));
        console.log(response.data);
        dispatch(loadUser(response?.data));
  
        toast({
          description: 'You are now logged in Successfully',
          status: 'success',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
  
        // Navigate based on user role
        if (response?.data?.role === 'admin' || response?.data?.role === 'manager') {
          navigate('/dashboard');
        } else if (localStorage.getItem('isUser')) {
          navigate('/cart');
          localStorage.setItem('isUser', false);
        } else {
          navigate('/');
        }
      } else {
        setLoading(false);
        toast({
          description: response?.message,
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error, 'while login user');
      toast({
        description: 'An error occurred during login. Please try again.',
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  


  async function handleGoogleLogin(name, email, picture) {
    const response = await POST('/users/login', {
      fullName: name,
      email: email,
      profile_picture: picture,
      provider: 'google',
      role: 'user',
    });
    console.log('Login Response', response, 'checking');
    if (response.status === 'success' && response.data) {
      setLoading(false);
      localStorage.setItem('ajwaUser', JSON.stringify(response.data));
      console.log(response.data);
      dispatch(loadUser(response?.data));

      toast({
        description: 'You are now logged in Successfully',
        status: 'success',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
      if (response?.data?.role === 'admin') {
        navigate('/dashboard');
      } else if (response?.data?.role === 'manager') {
        navigate('/dashboard');
      } else if (localStorage.getItem('isUser')) {
        navigate('/cart');
        localStorage.setItem('isUser', false);
      } else {
        navigate('/');
      }
    } else {
      setLoading(false);

      toast({
        description: response?.message,
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    }
  }
  const responseMessage = response => {
    if (response) {
      const { credential } = response;
      const decoded = jwtDecode(credential);
      const { email, name, picture } = decoded;
      handleGoogleLogin(name, email, picture);
    }
  };
  const errorMessage = error => {
    console.log(error);
  };

  return (
    <>
      <Stack px={{ base: '15px', md: '15px', xl: '0', '2xl': '0' }}>
        <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
          <Stack
            justifyItems={'center'}
            alignItems={'center'}
            px={8}
            bg={'#fff'}
            py={12}
            borderRadius={12}
            gap={8}
          >
            <Heading
              color={'#111'}
              fontSize={'25px'}
              fontWeight={700}
              textAlign={'center'}
            >
              Log In
            </Heading>
            <Stack gap={3} width={{ base: '100%', xl: '40%', '2xl': '40%' }}>
              <form onSubmit={handleSubmit}>
                <FormControl mt={3}>
                  <FormLabel fontSize={'15px'}>Email Address</FormLabel>
                  <Box className="Ifiled">
                    <Icon fontSize={'25px'} color={'#949494'} as={GoMail} />
                    <Input
                      name="email"
                      py={6}
                      type="email"
                      placeholder="Enter Your Email"
                    />
                  </Box>
                </FormControl>
                <FormControl mt={3}>
                  <FormLabel fontSize={'15px'}>Password</FormLabel>
                  <Box className="Ifiled">
                    <Icon
                      fontSize={'25px'}
                      color={'#949494'}
                      as={AiOutlineLock}
                    />
                    <Input
                      name="password"
                      py={6}
                      type={showpass ? 'text' : 'password'}
                      placeholder="Enter Your Password"
                    />
                    <Button
                      bg={'transparent'}
                      margin={'auto'}
                      _hover={{
                        bg: 'transparent',
                      }}
                      onClick={() => setshowpass(!showpass)}
                    >
                      <Icon
                        color={'#949494'}
                        as={showpass ? BsEye : BsEyeSlash}
                      />
                    </Button>
                  </Box>
                </FormControl>
                <Stack textAlign={'right'} py={3}>
                  <Link
                    fontWeight={600}
                    fontSize={'14px'}
                    as={ReactLink}
                    to={'/forgotpassword'}
                  >
                    Forgot Password?
                  </Link>
                </Stack>
                <Button
                  isLoading={loading}
                  bg={
                    'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                  }
                  fontSize={{ base: '12px', xl: '16px' }}
                  fontWeight={500}
                  color={'#fff'}
                  px={'40px'}
                  py={'24px'}
                  w={'100%'}
                  transition={'.3s'}
                  h={{ base: '35px', xl: '45px' }}
                  _hover={{
                    boxShadow: '0px 7px 20px 0px #0000003b',
                  }}
                  type="submit"
                >
                  Log In
                </Button>
              </form>
            </Stack>
            <Stack textAlign={'center'}>
              <Link
                fontWeight={600}
                fontSize={'14px'}
                as={ReactLink}
                to={'/signup'}
              >
                Not a member? Register
              </Link>
              <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </>
  );
}
