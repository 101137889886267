import { Box, Heading, Stack, Text, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { GET } from '../../../utilities/ApiProvider';
import { useParams } from 'react-router-dom';

export default function InquiriesUserDetails() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [inquiry, setInquiry] = useState({});
  useEffect(() => {
    fetchInquiry(id);
  }, [id]);

  async function fetchInquiry(id) {
    setIsLoading(true);
    try {
      const response = await GET(`/admin/contact/${id}`);
      if (response.status === 'success') {
        console.log('inquery ', response.data, id);
        setInquiry(response.data);
        setIsLoading(false);
      } else {
        setInquiry([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Stack gap={6} px={4} pb={6}>
      <Stack>
        <Heading fontSize={'30px'} fontWeight={'700'}>
          Inquiry Details
        </Heading>
        {/* <Text fontSize={'15px'} color={'#1A1B1A'} fontWeight={'400'}>
          Here is the all user details...Check Now!
        </Text> */}
      </Stack>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#47A046" />
        </Box>
      ) : (
        <Stack gap={4}>
          <Stack width={'30%'}>
            <Heading fontSize={'16px'}>Name</Heading>
            <Box
              borderRadius={6}
              py={4}
              px={3}
              border={'1px solid #e5e4e4'}
              bg={'#F8F8F8'}
            >
              <Text>{inquiry?.fullName || 'John'}</Text>
            </Box>
          </Stack>
          <Stack width={'30%'}>
            <Heading fontSize={'16px'}>Email Address</Heading>
            <Box
              borderRadius={6}
              py={4}
              px={3}
              border={'1px solid #e5e4e4'}
              bg={'#F8F8F8'}
            >
              <Text>{inquiry?.email || 'johndoe534@gmail.com'}</Text>
            </Box>
          </Stack>
          <Stack width={'30%'}>
            <Heading fontSize={'16px'}>Phone No.</Heading>
            <Box
              borderRadius={6}
              py={4}
              px={3}
              border={'1px solid #e5e4e4'}
              bg={'#F8F8F8'}
            >
              <Text>{inquiry?.phone || '+(966) 763 282 46'}</Text>
            </Box>
          </Stack>
          <Stack width={'30%'}>
            <Heading fontSize={'16px'}>Message</Heading>
            <Box
              borderRadius={6}
              py={4}
              px={3}
              border={'1px solid #e5e4e4'}
              bg={'#F8F8F8'}
            >
              <Text>
                {inquiry?.message ||
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
              </Text>
            </Box>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
