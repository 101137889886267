import {
  Stack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';

function Activity({ data }) {
  console.log(data, 'activity');
  return (
    <Stack gap={4}>
      <Stack className="one">
        <Stack>
          <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
            All Activity Logs
          </Text>
        </Stack>
        <Stack p={5} boxShadow="xl">
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Time</Th>
                  <Th>Activity Type</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.length > 0 &&
                  data.map((v, k) => {
                    return (
                      <Tr key={k}>
                        <Td>
                          <Text
                            color={'#1A1B1A'}
                            fontSize={'16px'}
                            fontWeight={600}
                          >
                            {moment(v?.createdAt).format('DD-MMM-YYYY') ||
                              '3/19/2024'}
                          </Text>
                        </Td>
                        <Td>
                          <Text
                            color={'#1A1B1A'}
                            fontSize={'16px'}
                            fontWeight={600}
                          >
                            {moment(v?.createdAt).format('hh:mm A') ||
                              '3/19/2024'}
                          </Text>
                        </Td>
                        <Td>
                          <Text
                            color={'#1A1B1A'}
                            fontSize={'16px'}
                            fontWeight={600}
                          >
                            {v?.logType}
                          </Text>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Activity;
