import TopBar from './TopBar';
import TopNav from './TopNav';

export default function Header() {
  return (
    <>
      <TopBar />
      <TopNav />
    </>
  );
}
