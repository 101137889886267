import {
    Avatar,
    Button,
    ListItem,
    Menu,
    MenuItem,
    MenuList,
    Stack,
    Text,
    UnorderedList,
    MenuButton,
    Box,
    Input,
    Icon,
  } from '@chakra-ui/react';
  import React, { useEffect, useState } from 'react';
  import { Link as ReactLink } from 'react-router-dom';
  import { useSelector } from 'react-redux';
  import LoggedInPic from './LoggedInPic';
  import { FiSearch } from 'react-icons/fi';
  
  export default function Topnav() {
    const user = useSelector(state => state);
  
    return (
      <>
        <Stack
          position={'relative'}
          mb={'6'}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'right'}
          bg={'#F2F2F2'}
          borderRadius={'0px 0px 0px 19px'}
          px={3}
          pl={6}
        >
          <Stack>
            <LoggedInPic />
          </Stack>
        </Stack>
      </>
    );
  }
  