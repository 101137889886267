import {
  Box,
  Button,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  Image,
  Grid,
  GridItem,
  Flex,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import Dollar from '../../../assets/images/website/dollor.svg';
import Star from '../../../assets/images/website/star1.svg';
import Card from '../../../assets/images/website/chip.png';

export default function BonusTopUp() {
  return (
    <>
      <Stack>
        <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
          <Stack
            alignItems={'center'}
            px={12}
            bg={'#fff'}
            py={12}
            borderRadius={12}
            gap={12}
          >
            <Grid templateColumns="repeat(6, 1fr)" gap={12} alignItems={'end'}>
              <GridItem colSpan={2}>
                <Stack gap={4}>
                  <Stack
                    p={6}
                    width={'420px'}
                    height={'233px'}
                    bg={
                      'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                    }
                    borderRadius={12}
                  >
                    <Flex
                      mb={8}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Heading
                        fontSize={'30px'}
                        color={'#fff'}
                        fontWeight={'700'}
                        lineHeight={'30px'}
                      >
                        Bonus Card
                      </Heading>
                      <Image width={'50px'} src={Card} alt={'Card'} />
                    </Flex>
                    <Heading
                      pb={12}
                      fontSize={'30px'}
                      color={'#fff'}
                      letterSpacing={3}
                      fontWeight={'700'}
                      textAlign={'center'}
                    >
                      <Text
                        as={'span'}
                        verticalAlign={'middle'}
                        fontSize={'40px'}
                      >
                        **** **** ****
                      </Text>{' '}
                      1535
                    </Heading>
                  </Stack>
                  <Stack>
                    <Heading
                      textAlign={'center'}
                      fontSize={'22px'}
                      color={'#111'}
                      fontWeight={'600'}
                    >
                      Current Balance: 00.00
                    </Heading>
                  </Stack>
                  <Stack alignItems={'center'}>
                    <Button
                      bg={
                        'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                      }
                      as={Link}
                      fontSize={{ base: '12px', xl: '16px' }}
                      fontWeight={500}
                      to={'/paymentmethods'}
                      width={'max-content'}
                      px={'40px'}
                      py={'24px'}
                      gap={2}
                      color={'#fff'}
                      transition={'.3s'}
                      h={{ base: '35px', xl: '45px' }}
                      _hover={{
                        boxShadow: '0px 7px 20px 0px #0000003b',
                      }}
                    >
                      Topup a Bonus Card
                    </Button>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem colSpan={4}>
                <Stack gap={24}>
                  <Flex
                    mb={12}
                    gap={6}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Stack alignItems={'center'}>
                      <Image width={'50px'} src={Dollar} alt={'Top Up'} />
                      <Text fontWeight={600} fontSize={'18px'} color={'#000'}>
                        Top up and spend online
                      </Text>
                    </Stack>
                    <Stack alignItems={'center'}>
                      <Image width={'50px'} src={Star} alt={'Top Up'} />
                      <Text fontWeight={600} fontSize={'18px'} color={'#000'}>
                        All your savings are safe and secure
                      </Text>
                    </Stack>
                    <Stack alignItems={'center'}>
                      <Image width={'50px'} src={Dollar} alt={'Top Up'} />
                      <Text fontWeight={600} fontSize={'18px'} color={'#000'}>
                        Top up and get 5% instant bonus
                      </Text>
                    </Stack>
                  </Flex>
                  <Stack alignItems={'center'}>
                    <Button
                      bg={
                        'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                      }
                      as={Link}
                      fontSize={{ base: '12px', xl: '16px' }}
                      fontWeight={500}
                      to={'/paymentmethods'}
                      width={'max-content'}
                      px={'40px'}
                      py={'24px'}
                      gap={2}
                      color={'#fff'}
                      transition={'.3s'}
                      h={{ base: '35px', xl: '45px' }}
                      _hover={{
                        boxShadow: '0px 7px 20px 0px #0000003b',
                      }}
                    >
                      Start Shopping
                    </Button>
                  </Stack>
                </Stack>
              </GridItem>
            </Grid>
          </Stack>
        </Container>
      </Stack>
    </>
  );
}
