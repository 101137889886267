import {
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import {
  RiEBike2Line,
  RiHeart2Line,
  RiChat2Line,
  RiSecurePaymentLine,
} from 'react-icons/ri';

export default function Support() {
  return (
    <>
      <Stack px={{ base: '15px', md: '15px', xl: '0', '2xl': '0' }}>
        <Container
          bg={'#fff'}
          py={12}
          my={6}
          borderRadius={12}
          maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}
        >

          <SimpleGrid columns={{ base: '1', sm: '2', lg: '4',xl: '4', '2xl': '4' }} spacing={2}>
            <Stack  alignItems={'center'} textAlign={'center'} >
                <Icon fontSize={'30px'} color={'#007341'} as={RiEBike2Line } />
                <Heading color={'#007341'} fontSize={{ base: '15px', sm: '15px', lg: '16px', xl: '17px','2xl': '20px' }} fontWeight={'600'} > Delivering All Over The Karachi</Heading>
                <Text fontSize={{ base: '12px', sm: '12px', lg: '13px', xl: '14px','2xl': '16px' }} fontWeight={'500'}>we deliver in 3 hours for orders above 1000 rupees</Text>

            </Stack>
            <Stack alignItems={'center'} textAlign={'center'}>
              <Icon fontSize={'30px'} color={'#007341'} as={RiHeart2Line} />
              <Heading
                color={'#007341'}
                fontSize={{
                  base: '15px',
                  sm: '15px',
                  lg: '16px',
                  xl: '17px',
                  '2xl': '20px',
                }}
                fontWeight={'600'}
              >
                100% Satisfaction Guarantee!
              </Heading>
              <Text
                fontSize={{
                  base: '12px',
                  sm: '12px',
                  lg: '13px',
                  xl: '14px',
                  '2xl': '16px',
                }}
                fontWeight={'500'}
              >
                Providing help in case of dissatisfaction
              </Text>
            </Stack>
            <Stack alignItems={'center'} textAlign={'center'}>
              <Icon fontSize={'30px'} color={'#007341'} as={RiChat2Line} />
              <Heading
                color={'#007341'}
                fontSize={{
                  base: '15px',
                  sm: '15px',
                  lg: '16px',
                  xl: '17px',
                  '2xl': '20px',
                }}
                fontWeight={'600'}
              >
                Top-Notch Support{' '}
              </Heading>
              <Text
                fontSize={{
                  base: '12px',
                  sm: '12px',
                  lg: '13px',
                  xl: '14px',
                  '2xl': '16px',
                }}
                fontWeight={'500'}
              >
                Chat with us if you’ve any questions
              </Text>
            </Stack>
            <Stack alignItems={'center'} textAlign={'center'}>
              <Icon
                fontSize={'30px'}
                color={'#007341'}
                as={RiSecurePaymentLine}
              />
              <Heading
                color={'#007341'}
                fontSize={{
                  base: '15px',
                  sm: '15px',
                  lg: '16px',
                  xl: '17px',
                  '2xl': '20px',
                }}
                fontWeight={'600'}
              >
                Secure Payments
              </Heading>
              <Text
                fontSize={{
                  base: '12px',
                  sm: '12px',
                  lg: '13px',
                  xl: '14px',
                  '2xl': '16px',
                }}
                fontWeight={'500'}
              >
                We use safest payment technologies
              </Text>
            </Stack>
          </SimpleGrid>
        </Container>
      </Stack>
    </>
  );
}
