import React from 'react'
import Header from '../../components/Website/Header'
import MiniBanner from '../../components/Website/banner/MiniBanner'
import Footer from '../../components/Website/Footer'
import NewPassform from '../../components/Website/Auth/NewPassform'
 

export default function NewPassword() {
  return (
    <>
    <Header/>
     <MiniBanner title={'New Password'} breadtitle={'New Password'} />
    <NewPassform/>
     <Footer/>
   </>
  )
}
