import React from 'react';
import Header from '../../components/Website/Header';
import MiniBanner from '../../components/Website/banner/MiniBanner';
import Footer from '../../components/Website/Footer';
import OrderHistory from '../../components/Website/Myacount/OrderHistory';

export default function History() {
  return (
    <>
      <Header />
      <MiniBanner title={'Order History'} breadtitle={'Order History'} />
      <OrderHistory />
      <Footer />
    </>
  );
}
