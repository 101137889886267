import React from 'react';
import Header from '../../components/Website/Header';
import MiniBanner from '../../components/Website/banner/MiniBanner';
import VerificationForm from '../../components/Website/Auth/VerificationForm';
import Footer from '../../components/Website/Footer';

export default function Verification() {
  return (
    <>
      <Header />
      <MiniBanner title={'Verification'} breadtitle={'Verification'} />
      <VerificationForm />
      <Footer />
    </>
  );
}
