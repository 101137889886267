import {
    Stack,
    Box,
    Image,
    Text,
    Heading,
    Icon,
    Checkbox,
    FormLabel,
    Input,
    Button,
    Container,
    useToast,
  } from '@chakra-ui/react';
  
  import { AiOutlineUser, AiOutlineLock } from 'react-icons/ai';
  import { BsEyeSlash, BsEye, BsBag } from 'react-icons/bs';
  import { GoMail } from 'react-icons/go';
  import React, { useEffect, useState } from 'react';
  import { Link ,useNavigate } from 'react-router-dom';
 
  import { POST } from '../../../utilities/ApiProvider';
  
  export default function NewPassform() {
    const toast = useToast();
    const navigate = useNavigate();
    const [showpass, setshowpass] = useState(false);
    const [reshowpass, setreshowpass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newpass, setnewpass] = useState({
      email: '',
      password: '',
      confirmPassword: '',
    });
    useEffect(() => {
      (async () => {
        let localEmail = localStorage.getItem('forgotemail');
        setnewpass({
          ...newpass,
          email: localEmail,
        });
        console.log('email', newpass);
      })();
    }, []);
  
    useEffect(() => {
      console.log(newpass);
    }, [newpass]);
  
    const EmpSubmit = async () => {
      setIsLoading(true);
  
      try {
        let res = await POST(`/users/changePassword`, newpass);
        console.log('add vender responce ', res);
  
        toast({
          description: res.message,
          status: res.status,
          position: 'top-right',
          duration: 3000,
        });
        if (res.status === 'success') {
          navigate('/login');
        }
      } catch (err) {
        toast({
          description: err.message,
          status: 'success',
          position: 'top-right',
          duration: 1000,
        });
      }
      setIsLoading(false);
    };
  
    return (
      <>
     <Stack px={{ base: '15px', md: '15px', xl: '0', '2xl': '0' }}>
      <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
        <Stack
          justifyItems={'center'}
          alignItems={'center'}
          px={6}
          bg={'#fff'}
          py={12}
          borderRadius={12}
          gap={8}
        >
          <Heading
            color={'#111'}
            fontSize={'25px'}
            fontWeight={700}
            textAlign={'center'}
          >
            New Password
          </Heading>
          <Stack  w={'30%'}   gap={4}>
            <Stack  >
              <FormLabel
             fontWeight={600}
             m={0}
             color={'#111'}
             fontSize={'14px'}
              >
                Password
              </FormLabel>
              <Box className="Ifiled" >
                <Icon   fontSize={'25px'} color={'#949494'} as={AiOutlineLock} />
                <Input
                  type={showpass ? 'text' : 'password'}
                  value={newpass.password}
                  onChange={e =>
                    setnewpass({ ...newpass, password: e.target.value })
                  }
                  placeholder="Enter Password"
                />
                <Button onClick={() => setshowpass(!showpass)}>
                  <Icon color={'#949494'} as={showpass ? BsEye : BsEyeSlash} />
                </Button>
              </Box>
            </Stack>
            <Stack>
              <FormLabel
                fontWeight={600}
                m={0}
                color={'#111'}
                fontSize={'14px'}
              >
                Re-type Password
              </FormLabel>
              <Box className="Ifiled">
                <Icon fontSize={'25px'} color={'#949494'} as={AiOutlineLock} />
                <Input
                  type={reshowpass ? 'text' : 'password'}
                  value={newpass.confirmPassword}
                  onChange={e =>
                    setnewpass({ ...newpass, confirmPassword: e.target.value })
                  }
                  placeholder="Enter Re-type Password"
                />
                <Button onClick={() => setreshowpass(!reshowpass)}>
                  <Icon color={'#949494'} as={reshowpass ? BsEye : BsEyeSlash} />
                </Button>
              </Box>
            </Stack>
  
            <Stack gap={14}>
              <Button
                 bg={
                    'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                  }
                  fontSize={{ base: '12px', xl: '16px' }}
                  fontWeight={500}
                  color={'#fff'}
                  
                  px={'40px'}
                  py={'24px'}
                  gap={2}
                  transition={'.3s'}
                  h={{ base: '35px', xl: '45px' }}
                  _hover={{
                    boxShadow: '0px 7px 20px 0px #0000003b',
                  }}
                isLoading={isLoading}
                onClick={EmpSubmit}
              >
                Continue
              </Button>
            </Stack>
          </Stack>
          </Stack>
      </Container>
    </Stack>
      </>
    );
  }
  