import {
  Stack,
  Container,
  Heading,
  Box,
  Image,
  Text,
  Button,
  Icon,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  SimpleGrid,
  HStack,
  Input,
  useNumberInput,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  TableContainer,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { RiCloseLargeLine } from 'react-icons/ri';
import Cart from '../../../assets/images/website/carts.svg';
import wishimg from '../../../assets/images/website/wishlist.jpg';
import { LuUserX, LuUserCheck } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCartData,
  incrementQuantity,
  decrementQuantity,
  removeFromCart,
  setCarts,
} from '../../../reducers/slices/cart.slice';
import { imgUrl } from '../../../utilities/config';
import { DELETE, GET, POST } from '../../../utilities/ApiProvider';
import {
  selectProductData,
  selectWebsiteData,
} from '../../../reducers/slices/website.slice';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { se } from 'date-fns/locale';
import { convertNumbertoCommaSeperate } from '../../../utilities/helper';

export default function AddCart() {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
    });
  const toast = useToast();
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const [cartId, setCartId] = useState(null);
  const input = getInputProps();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cart = useSelector(selectCartData);
  const product = useSelector(selectProductData);
  const { orderLimit } = useSelector(selectWebsiteData);
  console.log(orderLimit, 'arham');
  const user = useSelector(state => state.user.value);
  const [selectedService, setSelectedService] = useState('asGuest');
  const [number, setNumber] = useState(1);

  async function getCart() {
    try {
      const response = await GET('/store/cart');
      console.log(response, 'get Cart');
      if (response.status === 'success') {
        console.log(response.data, 'checking');
        setCartId(response.data._id);
        // console.log(response.data, 'checking');
        if (response.data.cartItems.length > 0) {
          dispatch(setCarts(response.data.cartItems || []));
        } else {
          dispatch(setCarts([]));
        }
      } else {
        console.log('Error while fetching cart');
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    // console.log('in use effect', localStorage.getItem('isUser') == 'false');
    if (user || localStorage.getItem('isUser') == 'false') {
      console.log('in user', cart);
      updateCart();
    }
  }, []);

  const handleRemove = async item => {
    if (user === undefined || user === null) {
      dispatch(removeFromCart(item));
    }
    console.log(cartId, item);

    if (cartId) {
      console.log(cartId, item);
      const response = await DELETE(`/store/cart/${cartId}?productId=${item}`);
      if (response.status === 'success') {
        dispatch(removeFromCart(item));

        toast({
          description: 'Product removed from cart successfully',
          status: 'success',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };
  const updateCart = async () => {
    // console.log('in update cart');
    try {
      if (cart.length > 0) {
        console.log(cart, 'cart');
        // console.log('entering update cart', cart);
        const response = await POST('/store/cart', {
          storeId: localStorage.getItem('storeId'),
          cartItems: cart,
        });
        console.log(response, 'success update');

        if (response.status === 'success' && response.data) {
          // console.log('in success get cart run');
          getCart();
        }
      } else {
        getCart();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleContinue = () => {
    let cost = cart?.reduce((total, sum) => {
      total += sum.price * sum.quantity;
      return total;
    }, 0);
    if (cost < orderLimit) {
      toast({
        description: `The minimum order limit is ${orderLimit}.`,
        status: 'success',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
      onClose();
      return;
    }
    if (selectedService === 'asGuest' && cost > orderLimit) {
      navigate('/checkout');
    } else if (selectedService === 'asUser' && cost > orderLimit) {
      localStorage.setItem('isUser', true);
      navigate('/login');
    }

    // updateCart();
    // navigate('/checkout');
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Choose an option</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack gap={4} pb={2}>
              <SimpleGrid columns={2} spacing={6}>
                <Stack
                  onClick={() => setSelectedService('asGuest')}
                  cursor={'pointer'}
                  borderRadius={6}
                  p={6}
                  border={
                    selectedService === 'asGuest'
                      ? '1px solid #59AA43'
                      : '1px solid #000'
                  }
                  textAlign={'center'}
                  alignItems={'center'}
                >
                  <Icon
                    fontSize={'40px'}
                    color={selectedService === 'asGuest' ? '#59AA43' : '#111'}
                    as={LuUserX}
                  />
                  <Text
                    color={selectedService === 'asGuest' ? '#59AA43' : '#111'}
                    fontSize={'17px'}
                    fontWeight={700}
                  >
                    As Guest
                  </Text>
                </Stack>
                <Stack
                  onClick={() => setSelectedService('asUser')}
                  cursor={'pointer'}
                  borderRadius={6}
                  p={6}
                  border={
                    selectedService === 'asUser'
                      ? '1px solid #59AA43'
                      : '1px solid #000'
                  }
                  textAlign={'center'}
                  alignItems={'center'}
                >
                  <Icon
                    fontSize={'40px'}
                    color={selectedService === 'asUser' ? '#59AA43' : '#111'}
                    as={LuUserCheck}
                  />
                  <Text
                    color={selectedService === 'asUser' ? '#59AA43' : '#111'}
                    fontSize={'17px'}
                    fontWeight={700}
                  >
                    As User
                  </Text>
                </Stack>
              </SimpleGrid>

              <Stack alignItems={'center'}>
                <Button
                  onClick={handleContinue}
                  // as={Link}
                  // to={'/checkout'}
                  fontSize={'16px'}
                  padding={'20px 35px'}
                  background={
                    'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                  }
                >
                  Continue
                </Button>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Stack px={{ base: '15px', md: '15px', xl: '0', '2xl': '0' }}>
        <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
          <Stack gap={6}>
            {cart?.length > 0 ? (
              <Stack px={8} bg={'#fff'} py={12} borderRadius={12}>
                <SimpleGrid
                  alignItems={'center'}
                  columns={{ base: '1', xl: '1', 1: '1' }}
                  spacing={10}
                >
                  <Stack>
                    <Heading
                      mb={6}
                      color={'#111'}
                      fontSize={'25px'}
                      fontWeight={700}
                    >
                      Items
                    </Heading>
                    <Box overflowX="auto">
                      <Box maxHeight="400px" overflowY="auto">
                        <Table variant="simple" size="sm">
                          <Thead
                            position="sticky"
                            top={0}
                            zIndex={1}
                            bg="white"
                          >
                            <Tr>
                              <Th>Products</Th>
                              <Th>Price</Th>
                              <Th>Quantity</Th>
                              <Th>Subtotal</Th>
                              <Th>Cancel</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {cart?.map((item, index) => {
                              console.log(item, 'item');
                              return (
                                <Tr key={item._id}>
                                  <Td>
                                    <Box
                                      display="flex"
                                      flexDirection={'column'}
                                      justifyContent="center"
                                      gap={2}
                                    >
                                      <Image
                                        w="100px"
                                        h="100px"
                                        src={
                                          item.productImage?.includes('https')
                                            ? item.productImage
                                            : `${imgUrl}/${item?.productImage}`
                                        }
                                      />
                                      <Text fontSize="15px">
                                        {item?.productName}
                                      </Text>
                                    </Box>
                                  </Td>
                                  <Td>
                                    <Text fontSize={'16px'}>
                                      {convertNumbertoCommaSeperate(
                                        item?.price
                                      )}
                                    </Text>
                                  </Td>
                                  <Td padding={'0px !important'}>
                                    <Box
                                      w="max-content"
                                      py="14.04px"
                                      px="0px"
                                      // p="14.04px 21.842px 14.04px 21.84px"
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      gap={1}
                                      borderRadius="26px"
                                    >
                                      <HStack id={item._id}>
                                        <Button
                                          // disabled={item.quantity >= item.stock}
                                          onClick={() => {
                                            setNumber(prev => prev + 1);
                                            dispatch(
                                              incrementQuantity(item.productId)
                                            );
                                          }}
                                        >
                                          <Icon w="12px" h="12px" as={FaPlus} />
                                        </Button>
                                        <Input
                                          defaultValue={
                                            user ? item.quantity : number
                                          }
                                          value={item.quantity}
                                          w="58px"
                                          textAlign="center"
                                        />
                                        <Button
                                          onClick={() => {
                                            if (item.quantity > 1) {
                                              setNumber(item.quantity - 1);
                                            }

                                            // Remove this line, as it prevents the rest of the code from running when number is 2
                                            // if (number <= 1) return;

                                            setNumber(prev => {
                                              console.log(
                                                item.quantity,
                                                number
                                              );

                                              // Adjusting the logic here
                                              if (item.quantity > 1) {
                                                return prev - 1;
                                              }
                                              return prev; // Keep the number unchanged when item.quantity is less than or equal to 1
                                            });

                                            dispatch(
                                              decrementQuantity(item.productId)
                                            );
                                          }}

                                          // onClick={() => {
                                          //   if (item.quantity > 1) {
                                          //     setNumber(item.quantity - 1);
                                          //   }

                                          //   if (number <= 1) return;
                                          //   setNumber(prev => {
                                          //     console.log(
                                          //       item.quantity,
                                          //       number
                                          //     );

                                          //     if (item.quantity) {
                                          //       return item.quantity - 1;
                                          //     }
                                          //     return prev - 1;
                                          //   });
                                          //   dispatch(
                                          //     decrementQuantity(item.productId)
                                          //   );
                                          // }}
                                        >
                                          <Icon
                                            w="12px"
                                            h="12px"
                                            as={FaMinus}
                                          />
                                        </Button>
                                      </HStack>
                                    </Box>
                                  </Td>
                                  <Td>
                                    <Text fontSize="16px">
                                      {convertNumbertoCommaSeperate(
                                        item?.price * item?.quantity
                                      )}
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Button
                                      onClick={() =>
                                        handleRemove(item?.productId)
                                      }
                                    >
                                      <Icon as={RiCloseLargeLine} />
                                    </Button>
                                  </Td>
                                </Tr>
                              );
                            })}
                          </Tbody>
                        </Table>
                      </Box>
                    </Box>

                    {/* <TableContainer>
                      <Table variant="simple">
                        <Thead>
                          <Tr>
                            <Th>Products</Th>
                            <Th>Quantity</Th>
                            <Th>Subtotal</Th>
                            <Th>Cancel</Th>
                          </Tr>
                        </Thead>
                      </Table>
                      <Box maxHeight="400px" overflowY="auto">
                        <Table variant="simple">
                          <Tbody>
                            {cart?.map((item, index) => {
                              return (
                                <Tr key={item._id}>
                                  <Td>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      gap={2}
                                    >
                                      <Image
                                        w="130px"
                                        h="100px"
                                        src={
                                          item.productImage?.includes('https')
                                            ? item.productImage
                                            : `${imgUrl}/${item?.productImage}`
                                        }
                                      />
                                      <Text
                                        maxW="150px"
                                        whiteSpace="nowrap"
                                        overflow="hidden"
                                        textOverflow="ellipsis"
                                        fontSize="15px"
                                      >
                                        {item?.productName}
                                      </Text>
                                    </Box>
                                  </Td>
                                  <Td>
                                    <Box
                                      w="max-content"
                                      p="14.04px 21.842px 14.04px 21.84px"
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      gap={1}
                                      borderRadius="26px"
                                    >
                                      <HStack id={item._id}>
                                        <Button
                                          onClick={() => {
                                            setNumber(prev => prev + 1);
                                            dispatch(
                                              incrementQuantity(item.productId)
                                            );
                                          }}
                                        >
                                          <Icon w="12px" h="12px" as={FaPlus} />
                                        </Button>
                                        <Input
                                          defaultValue={
                                            user ? item.quantity : number
                                          }
                                          value={item.quantity}
                                          w="58px"
                                          textAlign="center"
                                        />
                                        <Button
                                          onClick={() => {
                                            if (item.quantity > 1) {
                                              setNumber(item.quantity - 1);
                                            }
                                            if (number <= 1) return;
                                            setNumber(prev => {
                                              if (item.quantity) {
                                                return item.quantity - 1;
                                              }
                                              return prev - 1;
                                            });
                                            dispatch(
                                              decrementQuantity(item.productId)
                                            );
                                          }}
                                        >
                                          <Icon
                                            w="12px"
                                            h="12px"
                                            as={FaMinus}
                                          />
                                        </Button>
                                      </HStack>
                                    </Box>
                                  </Td>
                                  <Td>
                                    <Text fontSize="15px" textAlign="right">
                                      In Stock
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Button
                                      onClick={() =>
                                        handleRemove(item?.productId)
                                      }
                                    >
                                      <Icon as={RiCloseLargeLine} />
                                    </Button>
                                  </Td>
                                </Tr>
                              );
                            })}
                          </Tbody>
                        </Table>
                      </Box>
                    </TableContainer> */}
                  </Stack>
                  <Stack direction={'row'} justifyContent={'flex-end'}>
                    <Stack
                      w={'50%'}
                      px={4}
                      py={6}
                      bg={'#F3F3F3'}
                      borderRadius={6}
                    >
                      <Table variant="simple">
                        <Tbody>
                          <Tr>
                            <Td>
                              <Heading fontSize={'20px'} fontWeight={700}>
                                Subtotal
                              </Heading>
                            </Td>
                            <Td>
                              <Heading
                                fontSize={'20px'}
                                fontWeight={700}
                                bg={
                                  'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                                }
                                backgroundClip={'text'}
                              >
                                Rs{' '}
                                {convertNumbertoCommaSeperate(
                                  cart?.reduce((total, sum) => {
                                    total += sum.price * sum.quantity;
                                    return total;
                                  }, 0)
                                )}
                              </Heading>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Heading fontSize={'20px'} fontWeight={700}>
                                Total
                              </Heading>
                            </Td>
                            <Td>
                              <Heading
                                fontSize={'20px'}
                                fontWeight={700}
                                bg={
                                  'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                                }
                                backgroundClip={'text'}
                              >
                                Rs{' '}
                                {convertNumbertoCommaSeperate(
                                  cart?.reduce((total, sum) => {
                                    total += sum.price * sum.quantity;
                                    return total;
                                  }, 0)
                                )}
                              </Heading>
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                      <Button
                        onClick={() => {
                          updateCart();
                          if (!localStorage.getItem('isUser') && !user) {
                            onOpen();
                          } else {
                            let cost = cart?.reduce((total, sum) => {
                              total += sum.price * sum.quantity;
                              return total;
                            }, 0);
                            if (cost < orderLimit) {
                              toast({
                                description: `The minimum order limit is ${orderLimit}.`,
                                status: 'success',
                                position: 'top-right',
                                duration: 5000,
                                isClosable: true,
                              });
                              onClose();
                              return;
                            } else {
                              navigate('/checkout');
                            }
                          }
                        }}
                        fontSize={'16px'}
                        padding={'20px 35px'}
                        background={
                          'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                        }
                      >
                        Checkout
                      </Button>
                    </Stack>
                  </Stack>
                </SimpleGrid>
              </Stack>
            ) : (
              <Stack
                px={24}
                bg={'#fff'}
                py={12}
                // height={'400px'}
                borderRadius={12}
                alignItems={'center'}
                textAlign={'center'}
                gap={4}
              >
                <Image margin={'auto'} w={'80px'} src={Cart} />
                <Heading
                  color={'#007341'}
                  fontSize={{ base: '20px', xl: '25px', '2xl': '30px' }}
                  fontWeight={'700'}
                >
                  Your cart is currently empty
                </Heading>

                <Button
                  as={Link}
                  to={'/'}
                  border={'2px solid #07d57c'}
                  padding={'10px 25px'}
                  bg={'transparent'}
                  width={'max-content'}
                  onClick={() => navigate('/')}
                >
                  Return to shop
                </Button>
              </Stack>
            )}
          </Stack>
        </Container>
      </Stack>
    </>
  );
}
