import { Stack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import OrderDetails from '../../../components/Dashboard/Orders/OrderDetails'
import MainDashboard from '../../../components/DashNav/MainDashboard'
import { useParams } from 'react-router-dom';


export default function OrderDetail() {



  return (
    <Stack overflowY="hidden">
    <MainDashboard>
       <OrderDetails />
    </MainDashboard>
  </Stack>
  )
}
