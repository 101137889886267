import {
  Box,
  Heading,
  Image,
  Stack,
  Text,
  Avatar,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Spinner,
  TableContainer,
  Button,
  Tag,
  Icon,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as ReactLink, useNavigate, useParams } from 'react-router-dom';
import { GET, PUT } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import Product from '../../../assets/images/website/singleproduct.jpg';
import moment from 'moment/moment';
import { IoArrowBack } from 'react-icons/io5';

export default function OrderDetails() {
  const toast = useToast();
  const [Orderdetails, setOrderdetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const storeId = localStorage.getItem('storeId');
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  async function fetchOrderDetails() {
    setIsLoading(true);
    try {
      const response = await GET(`/store/order/${id}?storeId=${storeId}`);
      console.log('Order Details', response);

      if (response.status === 'success') {
        setOrderdetails(response.data);
        setIsLoading(false);
      } else {
        console.log('Error while fetching products');
      }
    } catch (error) {
      console.log(error, 'error while fetching products');
    }
  }

  const Productinfo = Orderdetails?.orderDetails;

  const handleUpdateStatus = async (orderId, status) => {
    try {
      const response = await PUT(`/admin/order/${orderId}`, {
        status,
      });
      console.log('Order Details', response);

      if (response.status === 'success') {
        setOrderdetails(prev => ({ ...prev, orderStatus: status }));
        toast({
          description: response?.message,
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      } else {
        console.log('Error while updating order status');
        toast({
          description: response?.message || response?.trace,
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log('Error while updating order status', error);
    }
  };

  return (
    <Stack gap={6} px={4} pb={6}>
      <Stack onClick={() => navigate(-1)} cursor={'pointer'}>
        <Icon as={IoArrowBack} w={10} h={10} />
      </Stack>
      <Stack>
        <Heading fontSize={'30px'} fontWeight={'700'}>
          Orders Details
        </Heading>
        {/* <Text fontSize={'15px'} color={'#1A1B1A'} fontWeight={'400'}>
          Here is the all user details...Check Now!
        </Text> */}
      </Stack>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#47A046" />
        </Box>
      ) : (
        <Stack bg={'#F5F5F5'} p={6} gap={6}>
          <Stack>
            <Heading fontSize={'18px'} fontWeight={'700'}>
              Order Status:
            </Heading>
            <Tag
              px={6}
              py={2}
              bg={'#007341'}
              width={'max-content'}
              color={'#fff'}
              textTransform={'capitalize'}
            >
              {Orderdetails?.orderStatus}
            </Tag>
          </Stack>
          <Stack gap={3}>
            <Heading fontSize={'18px'} fontWeight={'700'}>
              Customer Info:
            </Heading>
            <Stack
              direction={'row'}
              alignItems={'center'}
              gap={12}
              flexWrap={'wrap'}
            >
              <Box display={'flex'} gap={2} alignItems={'center'}>
                <Avatar
                  size="lg"
                  name={Orderdetails?.userId?.fullName}
                  src={`${imgUrl}${Orderdetails?.userId?.profile_picture}`}
                />
                <Box>
                  <Heading color={'#007341'} fontSize={'16px'}>
                    {Orderdetails?.name}
                  </Heading>
                  <Text fontWeight={600} fontSize={'12px'}>
                    {Orderdetails?.email}
                  </Text>
                </Box>
              </Box>
              <Box>
                <Heading color={'#007341'} fontSize={'16px'}>
                  Price
                </Heading>
                <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                  Rs : {Orderdetails?.total}
                </Text>
              </Box>
              <Box>
                <Heading color={'#007341'} fontSize={'16px'}>
                  Quantity
                </Heading>
                <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                  {Orderdetails?.qauntity}
                </Text>
              </Box>
              <Box>
                <Heading color={'#007341'} fontSize={'16px'}>
                  Purchased Date:
                </Heading>
                <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                  {moment(Orderdetails?.orderDate).format('DD-MMM-YYYY')}
                  {/* {new Date(Orderdetails?.orderDate).toLocaleDateString()} */}
                </Text>
              </Box>

              <Box>
                <Heading color={'#007341'} fontSize={'16px'}>
                  Delivery Date:
                </Heading>
                <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                  {moment(Orderdetails?.deliveryDate).format('DD-MMM-YYYY')}
                </Text>
              </Box>

              <Box>
                <Heading color={'#007341'} fontSize={'16px'}>
                  Phone
                </Heading>
                <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                  {Orderdetails?.phone}
                </Text>
              </Box>
              <Box>
                <Heading color={'#007341'} fontSize={'16px'}>
                  Addrress
                </Heading>
                <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                  {Orderdetails?.streetAddress}
                </Text>
              </Box>
            </Stack>
            <Box>
              <Heading color={'#007341'} fontSize={'16px'}>
                House No. / Building No.
              </Heading>
              <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                {Orderdetails?.houseNumber}
              </Text>
            </Box>
          </Stack>
          <Stack gap={3}>
            <Heading fontSize={'18px'} fontWeight={'700'}>
              Delivery Details:
            </Heading>
            <Stack
              direction={'row'}
              alignItems={'center'}
              gap={12}
              flexWrap={'wrap'}
            >
              <Box>
                <Heading color={'#007341'} fontSize={'16px'}>
                  Delivery Date:
                </Heading>
                <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                  {moment(Orderdetails?.deliveryDate).format('DD-MMM-YYYY')}
                </Text>
              </Box>
              <Box>
                <Heading color={'#007341'} fontSize={'16px'}>
                  Delivery Time Slot:
                </Heading>
                <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                  {`${Orderdetails?.deliveryId?.day} ${Orderdetails?.deliveryId?.time?.start} - ${Orderdetails?.deliveryId?.time?.end}`}
                </Text>
              </Box>
              <Box>
                <Heading color={'#007341'} fontSize={'16px'}>
                  Delivery Area
                </Heading>
                <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                  {Orderdetails?.area}
                </Text>
              </Box>
              <Box>
                <Heading color={'#007341'} fontSize={'16px'}>
                  Delivery Fee
                </Heading>
                <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                  {Orderdetails?.delivery}
                </Text>
              </Box>
              <Box>
                <Heading color={'#007341'} fontSize={'16px'}>
                  Notes:
                </Heading>
                <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                  {Orderdetails?.notes || 'No Notes'}
                </Text>
              </Box>
            </Stack>
          </Stack>
          {/* <Stack>
            <Heading fontSize={'18px'} fontWeight={'700'}>
              Notes:
            </Heading>
            <Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'}>
              <Box>
                <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                  {Orderdetails?.notes}
                </Text>
              </Box>
            </Stack>
          </Stack> */}
          <Stack>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Product Name</Th>
                    <Th>Quantity</Th>
                    <Th>Sku</Th>
                    <Th>Discount</Th>
                    <Th>Price</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Productinfo?.length > 0 &&
                    Productinfo.map((v, k) => {
                      return (
                        <Tr key={k}>
                          <Td>{v?.productId?.name}</Td>
                          <Td>{v?.quantity}</Td>
                          <Td>{v?.productId?.sku}</Td>
                          <Td>{v?.discount}</Td>
                          <Td>{v?.price}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} gap={3}>
              <Button
                padding={'10px 30px'}
                bg={
                  Orderdetails?.orderStatus === 'processing'
                    ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                    : '#D0D0D0'
                }
                color={
                  Orderdetails?.orderStatus === 'processing' ? '#fff' : '#000'
                }
                fontWeight={'600'}
                lineHeight={'inherit'}
                fontSize={'15px'}
                borderRadius={6}
                _hover={{
                  color: '#fff',
                  bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
                }}
                w={'fit-content'}
                onClick={() => handleUpdateStatus(id, 'processing')}
              >
                Order In Process
              </Button>
              <Button
                padding={'10px 30px'}
                bg={
                  Orderdetails?.orderStatus === 'shipping'
                    ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                    : '#D0D0D0'
                }
                color={
                  Orderdetails?.orderStatus === 'shipping' ? '#fff' : '#000'
                }
                fontWeight={'600'}
                lineHeight={'inherit'}
                fontSize={'15px'}
                borderRadius={6}
                _hover={{
                  color: '#fff',
                  bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
                }}
                w={'fit-content'}
                onClick={() => handleUpdateStatus(id, 'shipping')}
              >
                Order In Shipping
              </Button>
              <Button
                padding={'10px 30px'}
                bg={
                  Orderdetails?.orderStatus === 'shipped'
                    ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                    : '#D0D0D0'
                }
                color={
                  Orderdetails?.orderStatus === 'shipped' ? '#fff' : '#000'
                }
                fontWeight={'600'}
                lineHeight={'inherit'}
                fontSize={'15px'}
                borderRadius={6}
                _hover={{
                  color: '#fff',
                  bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
                }}
                w={'fit-content'}
                onClick={() => handleUpdateStatus(id, 'shipped')}
              >
                Ready To Ship
              </Button>
              <Button
                padding={'10px 30px'}
                bg={
                  Orderdetails?.orderStatus === 'complete'
                    ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                    : '#D0D0D0'
                }
                color={
                  Orderdetails?.orderStatus === 'complete' ? '#fff' : '#000'
                }
                fontWeight={'600'}
                lineHeight={'inherit'}
                fontSize={'15px'}
                borderRadius={6}
                _hover={{
                  color: '#fff',
                  bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
                }}
                w={'fit-content'}
                onClick={() => handleUpdateStatus(id, 'complete')}
              >
                Order is complete
              </Button>
            </Stack>
            <Stack>
              <Button
                padding={'10px 30px'}
                bg={
                  Orderdetails?.orderStatus === 'cancelled'
                    ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                    : '#D0D0D0'
                }
                color={
                  Orderdetails?.orderStatus === 'cancelled' ? '#fff' : '#000'
                }
                fontWeight={'600'}
                lineHeight={'inherit'}
                fontSize={'15px'}
                borderRadius={6}
                _hover={{
                  color: '#fff',
                  bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
                }}
                w={'fit-content'}
                onClick={() => handleUpdateStatus(id, 'cancelled')}
              >
                Cancel Order
              </Button>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
