import {
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Button,
  Icon,
  MenuGroup,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link  , useNavigate } from 'react-router-dom';
import { FaRegBell } from 'react-icons/fa';
import { FaCircle } from 'react-icons/fa';
import { FiChevronDown } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import  {logout}  from '../../reducers/useReducers';
import { imgUrl } from '../../utilities/config';

export default function LoggedInPic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.user.value);



  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      gap={{ base: 1, lg: 2, xl: 12 }}
      py={4}
    >
 
      <Menu>
        <MenuButton
          display={'flex'}
          direction={'row'}
          className="loggedBtn"
          as={Button}
          backgroundColor={'transparent'}
          padding={0}
          _hover={{ backgroundColor: 'transparent' }}
          _active={{ backgroundColor: 'transparent' }}
        >
          <VStack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            gap={2}
          >
            <Avatar
              size={{ base: 'sm', md: 'md' }}
              fontSize={'12px'}
              src={`${imgUrl}${user?.profile_picture}`}
              border={'2px solid #3B3D44'}
              bg={'#3B3D44;'}
              color={'#F2F2F2'}
              name={user?.fullName ?? 'John Anderson'}
            />
            <Text
              color={'#1B1C1D'}
              textTransform={'capitalize'}
              fontWeight={500}
              fontSize={{ base: 13, md: 14 }}
            >
              {user?.fullName ?? 'John Anderson'}
            </Text>
            <Icon color={'#1B1C1D'} as={FiChevronDown} />
          </VStack>
        </MenuButton>
        <MenuList border={'1px solid #3B3D44'} color={'#1B1C1D'} fontSize={14}>

          <MenuGroup title="Store Settings">
          <MenuItem
              as={Link}
              justifyContent={'left'}
              bg={'#FFFFFF'}
              _hover={{
                bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
              }}
              to={'/'}
            >
             Website
            </MenuItem>
            <MenuItem
              as={Link}
              justifyContent={'left'}
              bg={'#FFFFFF'}
              _hover={{
                bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
              }}
              to={'/dashboard/profile'}
            >
             My Profile
            </MenuItem>
            <MenuItem
               
              justifyContent={'left'}
              bg={'#FFFFFF'}
              _hover={{
                bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
              }}
              onClick={() => {
                dispatch(logout());

                navigate('/');
              }}
          >
            Logout
          </MenuItem>
          </MenuGroup>
          
        </MenuList>
      </Menu>
    
    </Stack>
  );
}
