import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from './reducers/rootReducer';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});
const persistor = persistStore(store);

// setInterval(() => {
//   persistor.purge();
// }, 10); // 1 hour in milliseconds
// Function to refresh the store
const refreshStore = () => {
  persistor.purge().then(() => {
    // Optionally, reset specific parts of the state if needed
    // For example:
    // store.dispatch(resetState());
  });
};

export { store, persistor, refreshStore };
