import { Box, Heading, Icon, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import bottel from '../../../assets/images/website/bottel.jpg';
import waterbottel from '../../../assets/images/website/waterbottel.jpg';
import { TbArrowBackUp } from 'react-icons/tb';
import { imgUrl } from '../../../utilities/config';
import { Link, useNavigate } from 'react-router-dom';

export default function CategoryBanner({ categories }) {
  return (
    <>
      <Stack textAlign={'center'} alignItems={'center'}>
        <Heading
          color={'#409D47'}
          fontSize={{ base: '20px', md: '25px', xl: '30px', '2xl': '30px' }}
          fontWeight={700}
        >
          {categories?.parent?.name}
        </Heading>
      </Stack>
    </>
  );
}
