import {
  Box,
  Button,
  Heading,
  Link,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import StoreCard from './StoreCard';
import { GET } from '../../../utilities/ApiProvider';

const Store = () => {
  useEffect(() => {
    fetchStores(); // Fetch products on component mount
  }, []);

  const [storelist, setstorelist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  async function fetchStores() {
    setIsLoading(true);
    try {
      const response = await GET(`/store`);
      console.log(response);
      if (response.status === 'success') {
        setstorelist(response?.data || []);
        setIsLoading(false);
      } else {
        setstorelist([]);
        console.log('Error while fetching products');
      }
    } catch (error) {
      console.log(error, 'error while fetching products');
      setstorelist([]);
    }
  }
  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading fontSize={'30px'} fontWeight={'700'}>
            Stores
          </Heading>
          {/* <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}></Text> */}
        </Stack>
        {/* <Stack
          alignItems={{ base: 'start', xl: 'end' }}
          display={'flex'}
          direction={'row'}
          justifyContent={'flex-end'}
          mr={4}
        >
          <Link
            padding={'10px 30px'}
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
            }
            color={'#fff'}
            fontWeight={'600'}
            lineHeight={'inherit'}
            fontSize={'15px'}
            borderRadius={6}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            to={'/dashboard/store/createbranch'}
            as={ReactLink}
          >
            Create New Store
          </Link>
        </Stack> */}
      </SimpleGrid>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#47A046" />
        </Box>
      ) : (
        <Stack>
          <Stack bg={'#FBFBFB'} pr={6} py={8} borderRadius={8}>
            <SimpleGrid
              columns={{
                base: '1',
                sm: '1',
                md: '1',
                lg: '2',
                xl: '2',
                '2xl': '2',
              }}
              spacingX="40px"
              spacingY="20px"
            >
              <StoreCard storelist={storelist} />
            </SimpleGrid>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default Store;
