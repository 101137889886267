import React, { useEffect } from 'react';
import Footer from '../../components/Website/Footer';
import Header from '../../components/Website/Header';
import MiniBanner from '../../components/Website/banner/MiniBanner';
import AboutImg from '../../assets/images/website/terms.jpg';
import {
  Container,
  Heading,
  Image,
  ListItem,
  OrderedList,
  SimpleGrid,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Add smooth scrolling behavior
    });
  }, []);

  return (
    <>
      <Header />
      <MiniBanner
        title={'Privacy Policy for Ajwa Supermart Phase 4 DHA'}
        breadtitle={'Privacy Policies'}
      />
      <Stack>
        <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
          <Stack px={12} bg={'#fff'} py={12} borderRadius={12}>
            <SimpleGrid
              //   columns={{ base: '1', md: '2', xl: '2', '2xl': '2' }}
              spacing={10}
            >
              <Stack>
                <Stack gap={6}>
                  <Heading color={'#111'} fontSize={'25px'} fontWeight={700}>
                    Introduction
                  </Heading>
                  <Text fontSize={'17px'} fontWeight={500}>
                    Welcome to Ajwa Supermart Phase 4 DHA. We value your privacy
                    and are committed to protecting your personal information.
                    This Privacy Policy outlines how we collect, use, share, and
                    safeguard your data when you visit our store or use our
                    services.
                  </Text>
                  {/* <Text fontSize={'17px'} fontWeight={500}>
                    Vestibulum sed arcu non odio. Aliquam ut porttitor leo a
                    diam sollicitudin tempor id. Interdum posuere lorem ipsum
                    dolor sit amet consectetur adipiscing. Convallis convallis
                    tellus id interdum velit laoreet.
                  </Text> */}
                  <Heading color={'#111'} fontSize={'25px'} fontWeight={700}>
                    Information We Collect
                  </Heading>
                  <Text fontSize={'17px'} fontWeight={500}>
                    We may collect the following types of information:
                  </Text>
                  <Text fontSize={'17px'} fontWeight={500}>
                    <OrderedList>
                      <ListItem>
                        <b>Personal Identification Information:</b> Name,
                        contact details (phone number, email address), and
                        payment information.
                      </ListItem>
                      <ListItem>
                        <b>Transaction Data:</b> Purchase history, transaction
                        details, and preferences.
                      </ListItem>
                      <ListItem>
                        <b>Technical Data:</b> IP address, browser type, and
                        usage data when interacting with our website or online
                        services.
                      </ListItem>
                      <ListItem>
                        <b>Location Data:</b> If applicable, your location
                        information when you use location-based services.
                      </ListItem>
                    </OrderedList>
                  </Text>
                  <Heading color={'#111'} fontSize={'25px'} fontWeight={700}>
                    How We Use Your Information
                  </Heading>
                  <Text fontSize={'17px'} fontWeight={500}>
                    We use your information for the following purposes:
                  </Text>
                  <Text fontSize={'17px'} fontWeight={500}>
                    <OrderedList>
                      <ListItem>
                        <b>To Process Transactions:</b> To fulfill your orders,
                        process payments, and provide receipts.
                      </ListItem>
                      <ListItem>
                        <b>To Improve Our Services: </b> To understand customer
                        preferences and enhance our offerings.
                      </ListItem>
                      <ListItem>
                        <b>For Customer Communication :</b> To send order
                        confirmations, updates, promotional offers, and
                        newsletters (with your consent).
                      </ListItem>
                      <ListItem>
                        <b>For Legal Compliance: </b> To comply with legal
                        obligations and protect our rights.
                      </ListItem>
                    </OrderedList>
                  </Text>
                  <Heading color={'#111'} fontSize={'25px'} fontWeight={700}>
                    Sharing Your Information
                  </Heading>
                  <Text fontSize={'17px'} fontWeight={500}>
                    We may share your information with:
                  </Text>
                  <Text fontSize={'17px'} fontWeight={500}>
                    <OrderedList>
                      <ListItem>
                        <b>Service Providers:</b> Third parties that assist us
                        in operating our business, such as payment processors,
                        delivery services, and marketing agencies.
                      </ListItem>
                      <ListItem>
                        <b>Legal Authorities: </b> When required by law or to
                        protect our rights and interests.
                      </ListItem>
                      <ListItem>
                        <b>Business Transfers:</b> In connection with a merger,
                        acquisition, or sale of assets.
                      </ListItem>
                    </OrderedList>
                  </Text>
                  <Heading color={'#111'} fontSize={'25px'} fontWeight={700}>
                    Data Security
                  </Heading>
                  <Text fontSize={'17px'} fontWeight={500}>
                    We implement reasonable security measures to protect your
                    information from unauthorized access, disclosure,
                    alteration, or destruction. However, no system is entirely
                    secure, and we cannot guarantee the absolute security of
                    your data.
                  </Text>
                  <Heading color={'#111'} fontSize={'25px'} fontWeight={700}>
                    Your Rights
                  </Heading>
                  <Text fontSize={'17px'} fontWeight={500}>
                    <OrderedList>
                      <ListItem>
                        <b>Access and Update:</b> Review and update your
                        personal information.
                      </ListItem>
                      <ListItem>
                        <b>Opt-Out: </b> Unsubscribe from marketing
                        communications at any time.
                      </ListItem>
                      <ListItem>
                        <b>Delete:</b> Request the deletion of your personal
                        data, subject to legal and contractual obligations. To
                        exercise these rights, please contact us using the
                        information provided below.
                      </ListItem>
                    </OrderedList>
                  </Text>
                  <Heading color={'#111'} fontSize={'25px'} fontWeight={700}>
                    Cookies and Tracking Technologies
                  </Heading>
                  <Text fontSize={'17px'} fontWeight={500}>
                    We use cookies and similar technologies to enhance your
                    experience on our website. Cookies help us remember your
                    preferences and track your usage patterns. You can manage
                    your cookie preferences through your browser settings.
                  </Text>
                  <Heading color={'#111'} fontSize={'25px'} fontWeight={700}>
                    Changes to This Policy
                  </Heading>
                  <Text fontSize={'17px'} fontWeight={500}>
                    We may update this Privacy Policy from time to time. Any
                    changes will be posted on our website, and the effective
                    date will be updated. We encourage you to review this policy
                    periodically.
                  </Text>
                  <Heading color={'#111'} fontSize={'25px'} fontWeight={700}>
                    Contact Us
                  </Heading>
                  <Text fontSize={'17px'} fontWeight={500}>
                    If you have any questions or concerns about this Privacy
                    Policy or how we handle your data, please contact us at:
                  </Text>
                  <Text fontSize={'17px'} fontWeight={500}>
                    <UnorderedList>
                      <ListItem>
                        <b>Address: </b> 22c phase 4 dha (Ajwa Supermart Phase 4
                        DHA)
                      </ListItem>
                      <ListItem>
                        <b>Email: </b> mradilshaikh@yahoo.com
                      </ListItem>
                      <ListItem>
                        <b>Phone: </b>02135802592
                      </ListItem>
                    </UnorderedList>
                  </Text>
                </Stack>
              </Stack>
            </SimpleGrid>
          </Stack>
        </Container>
      </Stack>
      <Footer />
    </>
  );
}
