import {
  Box,
  Container,
  Icon,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  Link,
  Flex,
  FormControl,
  Input,
  FormLabel,
  Select,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import {
  FaPhone,
  FaWhatsapp,
  FaSquareFacebook,
  FaInstagram,
} from 'react-icons/fa';
import { RiFacebookCircleLine } from 'react-icons/ri';
import { FaMobileAlt } from 'react-icons/fa';
import { GrLocation } from 'react-icons/gr';
import { BiTime } from 'react-icons/bi';
import { Link as ReactLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { GET } from '../../../utilities/ApiProvider';
import { setWebsiteData } from '../../../reducers/slices/website.slice';
import ModalWrapper from '../../../components/Modal';
function ChangePassword({ onClose }) {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  useEffect(() => {
    async function fetchArea() {
      try {
        const response = await GET('/store');
        if (response.status === 'success') {
          setData(response.data);
        } else {
          console.log('Error while fetching area');
        }
      } catch (error) {
        console.log(error, 'error while fetching area');
      }
    }
    fetchArea();
  }, []);
  async function getHomeData() {
    try {
      const response = await GET(`/users/webHome?storeId=${selectedArea}`);
      dispatch(setWebsiteData(response));
    } catch (error) {
      console.error('An error occurred while fetching user data:', error);
    }
  }
  const handleSubmit = () => {
    if (selectedArea !== null) {
      localStorage.setItem('storeId', selectedArea);
      getHomeData();

      onClose();
    }
  };
  return (
    <Stack display={'flex'} flexDirection={'column'} gap={3}>
      <FormControl>
        <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
          Branch
        </FormLabel>

        <Select onChange={e => setSelectedArea(e.target.value)} size={'lg'}>
          <option value="Select">Select</option>
          {data?.map(item => (
            <option value={item._id}>{item.area}</option>
          ))}
        </Select>
      </FormControl>
      <Stack py={3}>
        <Button
          padding={'10px 50px'}
          bg={
            'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
          }
          color={'#fff'}
          fontWeight={'500'}
          lineHeight={'inherit'}
          borderRadius={12}
          h="45px"
          _hover={{
            color: '#000',
          }}
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
}
export default function TopBar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // useEffect(() => {
  //   const storeId = localStorage.getItem('storeId');
  //   if (!storeId) {
  //     onOpen();
  //   }
  // }, []);
  return (
    <Stack>
      <Container
        py="10px"
        bgGradient="radial-gradient(299.53% 303.74% at -27.27% -88.35%, #FFF 0%, #FBB03B 50%, #C4272E 100%)"
        maxW={{ base: '8xl', lg: '6xl', '2xl': '8xl' }}
      >
        <ModalWrapper
          isOpen={isOpen}
          onClose={onClose}
          title={'Choose your location'}
          // subTitle={'You want to “Unblock” this user?'}
          children={<ChangePassword onClose={onClose} />}
          size={'lg'}
          isCentered={true}
        />
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={{
            base: 'center',
            sm: 'space-between',
            lg: 'space-between',
            xl: 'space-between',
            '2xl': 'space-between',
          }}
        >
          <UnorderedList
            display={{ base: 'none', lg: 'flex', xl: 'flex', '2xl': 'flex' }}
            alignItems={'center'}
            gap={{ base: '10px', '2xl': '40px' }}
            listStyleType={'none'}
            color={'white'}
          >
            {TopNav_ITEMS.map(navItem => (
              <ListItem>
                <Link
                  as={navItem.btn ? '' : ReactLink}
                  to={navItem.btn ? '' : navItem.href}
                  color={'#111'}
                  fontSize={{
                    base: '12px',
                    lg: '13px',
                    xl: '14px',
                    '2xl': '15px',
                  }}
                  fontWeight={600}
                  onClick={navItem.btn ? onOpen : null}
                >
                  {navItem.label}
                </Link>
              </ListItem>
            ))}
          </UnorderedList>
          <UnorderedList
            display={{
              base: 'none',
              sm: 'flex',
              lg: 'none',
              xl: 'flex',
              '2xl': 'flex',
            }}
            alignItems={'center'}
            gap={{ base: '10px', '2xl': '40px' }}
            listStyleType={'none'}
            color={'white'}
          >
            <ListItem>
              <Text
                fontSize={{
                  base: '12px',
                  lg: '13px',
                  xl: '13px',
                  '2xl': '14px',
                }}
                color={'#111'}
                fontWeight={600}
              >
                {' '}
                Currently offering delivery services in Karachi Only.
              </Text>
            </ListItem>
          </UnorderedList>
          <UnorderedList
            display={'flex'}
            alignItems={'center'}
            gap={{ base: '10px', '2xl': '40px' }}
            listStyleType={'none'}
            color={'white'}
          >
            <ListItem
              display={'flex'}
              alignItems={'center'}
              gap={{ base: '5px', '2xl': '15px' }}
            >
              <Box
                color={'#111'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Icon
                  fontSize={{
                    base: '12px',
                    lg: '14px',
                    xl: '16px',
                    '2xl': '17px',
                  }}
                  as={FaMobileAlt}
                />
              </Box>
              <Text
                fontSize={{
                  base: '10px',
                  lg: '13px',
                  xl: '13px',
                  '2xl': '14px',
                }}
                color={'#111'}
                fontWeight={600}
              >
                Phone:{' '}
                <Text
                  as="a"
                  href={`https://wa.me/923302538519`}
                  target="_blank"
                >
                  03302538519
                </Text>
              </Text>
            </ListItem>
            <ListItem
              display={'flex'}
              alignItems={'center'}
              gap={{ base: '5px', '2xl': '15px' }}
            >
              <Box
                color={'#111'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Icon
                  fontSize={{
                    base: '12px',
                    lg: '14px',
                    xl: '16px',
                    '2xl': '17px',
                  }}
                  as={FaPhone}
                />
              </Box>
              <Text
                fontSize={{
                  base: '10px',
                  lg: '13px',
                  xl: '13px',
                  '2xl': '14px',
                }}
                color={'#111'}
                fontWeight={600}
              >
                Phone:{' '}
                <Text as="a" href="tel:02135802592">
                  02135802592
                </Text>
              </Text>
            </ListItem>
            <ListItem>
              <Flex gap={{ base: '2', sm: '2', lg: '4', xl: '4', '2xl': '4' }}>
                <Link
                  target="_blank"
                  as={'a'}
                  href={'https://www.facebook.com/ajwasupermartkhi/'}
                >
                  <Icon
                    color={'#000'}
                    fontSize={{
                      base: '14px',
                      sm: '18px',
                      lg: '18px',
                      xl: '20px',
                      '2xl': '20px',
                    }}
                    as={RiFacebookCircleLine}
                  />
                </Link>
                <Link
                  target="_blank"
                  as={'a'}
                  href={
                    'https://www.instagram.com/ajwasupermartdha/?utm_source=qr&igsh=MTVrMW5nMjZxOHgzcA'
                  }
                >
                  <Icon
                    color={'#000'}
                    fontSize={{
                      base: '14px',
                      sm: '18px',
                      lg: '18px',
                      xl: '20px',
                      '2xl': '20px',
                    }}
                    as={FaInstagram}
                  />
                </Link>
                <Link
                  as={'a'}
                  href={`https://wa.me/923302538519`}
                  target="_blank"
                >
                  <Icon
                    color={'#000'}
                    fontSize={{
                      base: '14px',
                      sm: '18px',
                      lg: '18px',
                      xl: '20px',
                      '2xl': '20px',
                    }}
                    as={FaWhatsapp}
                  />
                </Link>
              </Flex>
            </ListItem>
          </UnorderedList>
        </Stack>
      </Container>
    </Stack>
  );
}

const TopNav_ITEMS = [
  {
    label: 'Home',
    href: '/',
  },

  {
    label: 'About Us',
    href: '/about',
  },
  {
    label: 'Contact Us',
    href: '/contactus',
  },
  // For Modal Open
  // {
  //   label: 'Store Finder',
  //   btn: true,
  // },
  {
    label: 'Store Finder',
    href: '/storefinder',
  },
];
