import {
  Box,
  Button,
  Heading,
  HStack,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import MainDashboard from '../../DashNav/MainDashboard';
import { DELETE, GET } from '../../../utilities/ApiProvider';
import DeliveryModal from './DeliveryModal';
import moment from 'moment';

function DeliveryAreas() {
  const toast = useToast();
  const [deliveryAreas, setDeliveryAreas] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState(null);
  

  const fetchDeliveryAreas = async () => {
    try {
      try {
        const response = await GET(
          `/store/delivery/area?storeId=66792cc177d1c268a6bf34af`
        );
        console.log('Delivery Areas', response);

        if (response.status === 'success') {
          setDeliveryAreas(response.data || []);
        } else {
          setDeliveryAreas([]);
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchDeliveryAreas();
  }, []);

  async function deleteItem(id) {
    try {
      const response = await DELETE(`/admin/delivery/area/${id}`);
      if (response.status === 'success') {
        onClose();
        toast({
          title: 'Delivery Area deleted successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        fetchDeliveryAreas();
      } else {
        toast({
          title: `${response?.trace || 'Error deleting delivery area'}`,
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  function openEditModal(value) {
    setSelected(value);
    onOpen();
  }
  return (
    <Stack overflowY="hidden">
      <MainDashboard>
        <Stack
          mb={'30px !important'}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          px={2}
        >
          <Stack display={'flex'} flexDirection={'column'}>
            <Heading fontSize={'30px'} fontWeight={'600'}>
              Delivery Areas
            </Heading>
          </Stack>

          <Button
            padding={'10px 30px'}
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
            }
            mr={6}
            color={'#fff'}
            fontWeight={'600'}
            lineHeight={'inherit'}
            fontSize={'15px'}
            borderRadius={6}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            onClick={onOpen}
          >
            Add New Delivery Area
          </Button>
        </Stack>
        <Stack mt={'50px'}>
          <Stack display={'flex'} flexDirection={'column'} gap={2}>
            <HStack display={'flex'} w="94%" px={4}>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                Area
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                Delivery Fee
              </Box>
              <Box fontSize="15px" fontWeight={'400'} color="#75767A" flex={1}>
                Created At
              </Box>
              <Box
                fontSize="15px"
                fontWeight={'400'}
                color="#75767A"
                flex={1}
                justifyContent={'center'}
                display={'flex'}
              >
                Action
              </Box>
            </HStack>
            {deliveryAreas?.map((v, index) => (
              <HStack
                display={'flex'}
                w="95%"
                h="70px"
                bg={'#fff'}
                shadow={'0px 16px 38px 0px #0000000F'}
                px={4}
                borderRadius={'16px'}
              >
                <Box
                  fontSize="15px"
                  fontWeight={'600'}
                  color="#1B1C1D"
                  flex={1}
                >
                  {v?.area}
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'600'}
                  color="#1B1C1D"
                  flex={1}
                >
                  {v?.fee}
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'600'}
                  color="#1B1C1D"
                  flex={1}
                >
                  {moment(v?.createdAt).format('DD-MMM-YYYY')}
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'600'}
                  color="#1B1C1D"
                  flex={1}
                  display="flex"
                  gap={'15px'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Button
                    padding={'10px 50px'}
                    bg={
                      'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                    }
                    color={'#fff'}
                    fontWeight={'600'}
                    lineHeight={'inherit'}
                    borderRadius={12}
                    as={'button'}
                    onClick={() => openEditModal(v)}
                    _hover={{
                      color: '#000',
                    }}
                    w={'fit-content'}
                  >
                    Edit
                  </Button>
                  <Button
                    padding={'10px 50px'}
                    bg={
                      'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                    }
                    color={'#fff'}
                    fontWeight={'600'}
                    lineHeight={'inherit'}
                    borderRadius={12}
                    as={'button'}
                    onClick={() => {
                      deleteItem(v?._id);
                    }}
                    _hover={{
                      color: '#000',
                    }}
                    w={'fit-content'}
                  >
                    Delete
                  </Button>
                </Box>

                {/* <Box fontSize="15px" fontWeight={'500'} color="#1B1C1D" flex={1}>
              <Button
                padding={'10px 50px'}
                bg={
                  'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                }
                color={'#fff'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                as={Link}
                to={`/dashboard/productdetails/${v?._id}`}
                _hover={{
                  color: '#000',
                }}
                w={'fit-content'}
              >
                View Details
              </Button>
            </Box> */}
              </HStack>
            ))}
          </Stack>
        </Stack>
      </MainDashboard>
      <DeliveryModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        selected={selected}
        setData={setSelected}
        refetch={fetchDeliveryAreas}
      />
    </Stack>
  );
}

export default DeliveryAreas;
