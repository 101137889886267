import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  ModalCloseButton,
  Button,
  useDisclosure,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { DELETE, POST, PUT } from '../../../utilities/ApiProvider';

const DeliveryModal = ({
  isOpen,
  onOpen,
  onClose,
  selected,
  setData,
  refetch,
}) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    const data = new FormData(e.target);
    // ------------------------------------------
      for (let [key, value] of data.entries()) {
    if (!value.trim()) {  // Check if the value is empty or just whitespace
      toast({
        title: `${key} cannot be blank`,  // Display a message indicating which field is blank
        status: 'error',
        duration: 3000,
        position: 'top-right',
        isClosable: true,
      });
      setIsLoading(false);  // Stop loading state
      return;  // Stop the form submission
    }
  }
    // -------------------------------------
    console.log('Data Val', data);
    let response;
    if (selected) {
      response = await PUT(`/admin/delivery/area/${selected?._id}`, data);
    } else {
      response = await POST('/admin/delivery/area', data);
    }
    if (response.status === 'success') {
      setIsLoading(false);
      onClose();
      e.target.reset();
      setData(null);
      toast({
        title: `${
          selected
            ? 'Product updated successfully'
            : 'Product added successfully'
        }`,
        status: 'success',
        duration: 3000,
        position: 'top-right',
        isClosable: true,
      });

      refetch();
    } else {
      setIsLoading(false);
      toast({
        title: `${response?.trace}`,
        status: 'error',
        duration: 3000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  function onCloseModal() {
    onClose();
    // Reset the form
    document.getElementById('formSubmit').reset();
    setData(null);
  }

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delivery Areas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <form id="formSubmit" onSubmit={handleSubmit}>
                <FormControl my={3}>
                  <FormLabel fontSize={'15px'}>Delivery Area</FormLabel>
                  <Input
                    py={6}
                    name="area"
                    defaultValue={selected && selected?.area}
                    type="text"
                    placeholder="Enter Product Name"
                  />
                </FormControl>
                <FormControl my={3}>
                  <FormLabel fontSize={'15px'}>Fee</FormLabel>
                  <Input
                    py={6}
                    name="fee"
                    defaultValue={selected && selected?.fee}
                    type="number"
                    placeholder="Rs. 0.00"
                  />
                </FormControl>
                <Button
                  my={4}
                  type="submit"
                  isLoading={isLoading}
                  bg={
                    'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                  }
                  color={'#000'}
                  width={'100%'}
                >
                  Save Changes
                </Button>
              </form>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default DeliveryModal;
