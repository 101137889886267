import React, { useEffect } from 'react'
import Header from '../../components/Website/Header'
import Footer from '../../components/Website/Footer'
import DiscountOfferProducts from '../../components/Website/discount/DiscountOfferProducts';
import { useParams } from 'react-router-dom';

export default function DiscountProducts() {
    const { id } = useParams();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    }, []);

  return (
    <>
    <Header/>
  <DiscountOfferProducts id={id}/>
     
    <Footer/>
    </>
  )
}
