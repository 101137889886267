import {
    Stack,
    Box,
    Image,
    Text,
    Heading,
    Icon,
    Checkbox,
    FormLabel,
    Input,
    Button,
    
    Toast,
    useToast,
    FormControl,
    Container
  } from '@chakra-ui/react';
  import { GoMail } from 'react-icons/go';
  import React, { useState } from 'react';
  import { useNavigate,Link } from 'react-router-dom';
  import { POST } from '../../../utilities/ApiProvider';
  import { baseUrl } from '../../../utilities/config';

export default function Forgot() {

    const toast = useToast();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [ForgotInfo, setForgotInfo] = useState({
      email: '',
    });

    
    const ForgotSubmit = async () => {
        setIsLoading(true);
        try {
          if (ForgotInfo.email === '') {
            toast({
              description: 'All fields are required!',
              status: 'error',
              position: 'top-right',
              duration: 1000,
            });
          } else {
            let res = await POST(`/users/forgetPassword`, ForgotInfo);
            console.log('login responce', res);
    
            toast({
              description: res.message,
              status: res.status,
              position: 'top-right',
              duration: 3000,
            });
            if (res.status === 'success') {
              localStorage.setItem('forgotemail', ForgotInfo.email);
    
              navigate('/verification');
            } else {
            }
          }
        } catch (err) {
          Toast({
            description: err.message,
            status: 'success',
            position: 'top-right',
            duration: 1000,
          });
        }
        setIsLoading(false);
      };
   
  return (
    <>
    <Stack px={{ base: '15px', md: '15px', xl: '0', '2xl': '0' }}>
      <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
        <Stack
          justifyItems={'center'}
          alignItems={'center'}
          px={8}
          bg={'#fff'}
          py={12}
          borderRadius={12}
          gap={8}
        >
          <Heading
            color={'#111'}
            fontSize={'25px'}
            fontWeight={700}
            textAlign={'center'}
          >
            Forgot Password
          </Heading>
          <Stack gap={3} width={{ base: '100%', xl: '40%', '2xl': '40%' }}>
          <FormControl mt={3}>
                <FormLabel fontSize={'15px'}>Email Address</FormLabel>
                <Box className="Ifiled">
                    <Icon
                      fontSize={'25px'}
                      color={'#949494'}
                      as={GoMail}
                    />
                    <Input
                 value={ForgotInfo.email}
                 onChange={e =>
                   setForgotInfo({ ...ForgotInfo, email: e.target.value })
                 }
                  name="email"
                  py={6}
                  type="email"
                  placeholder="Enter Your Email"
                />
                    </Box>
                
              </FormControl>
               
              
              <Button
               isLoading={isLoading}
               onClick={ForgotSubmit}
                w="100%"
                mt={3}
                type="submit"
                fontSize={'16px'}
                padding={'20px 25px'}
                background={
                  'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                }
              >
                Submit
              </Button>
          </Stack>
          <Stack textAlign={'center'}>
            <Link
              fontWeight={600}
              fontSize={'14px'}
              as={Link}
              to={'/signup'}
            >
              Not a member? Register
            </Link>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  </>
  )
}
