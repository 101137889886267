import {
  Box,
  Button,
  Grid,
  Heading,
  Icon,
  Stack,
  Text,
  VStack,
  HStack,
  Avatar,
  Spinner,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import TeamCard from '../Team/TeamCard';
import { GET, POST } from '../../../utilities/ApiProvider';
import { LuUser } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';
import { imgUrl } from '../../../utilities/config';
import Pagination from '../../../utilities/Pagination';

export default function Userlist() {
  const navigate = useNavigate();
  const toast = useToast();
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [SelectService, setSelectService] = useState('unblocked');
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  async function fetchOrder() {
    setIsLoading(true);
    try {
      const storeId = localStorage.getItem('storeId');
      const response = await GET(
        `/users/allusers?storeId=${storeId}&page=${
          (currentPage - 1) * itemsPerPage
        }&limit=${itemsPerPage}&status=${SelectService}`
      );
      // const response = await GET(`/users/allusers/?storeId=${storeId}`);
      // users/allusers?storeId=66792cc177d1c268a6bf34af&status=unblocked&limit=12&page=2
      console.log('users', response);
      if (response.status === 'success' && response.data) {
        setTotalItems(response.count);
        // console.log(Object.keys(response.data)[0]);
        // setSelectServices(Object.keys(response.data)[0]);
        setSelectedOrder(response.data);
        setIsLoading(false);
      } else {
        console.error(
          'Error while fetching users:',
          response.message || 'Unknown error'
        );
      }
    } catch (error) {
      console.error('Error while fetching users:', error);
    }
  }

  useEffect(() => {
    fetchOrder();
  }, [currentPage, SelectService]);

  const handleRemove = async userId => {
    try {
      const response = await POST(`/users/blockuser/${userId}?action=unblock`);

      if (response.status === 'success') {
        toast({
          description: response?.message,
          status: response?.status,
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        fetchOrder();
      } else {
        toast({
          description: response?.message,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [SelectService]);

  return (
    <Stack px={6} py={4}>
      <Stack display={'flex'} flexDirection={'column'}>
        <Heading fontSize={'30px'} fontWeight={'600'}>
          Users
        </Heading>
        {/* <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the latest update...Check Now!
        </Text> */}
      </Stack>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#47A046" />
        </Box>
      ) : (
        <Stack>
          <Stack direction={'row'} alignvs={'center'} gap={4} flexWrap={'wrap'}>
            {/* {Object.keys(selectedOrder)?.map(v => (
             
            ))} */}
            <Button
              border={
                SelectService === 'unblocked'
                  ? '2px solid #EAA13E'
                  : '2px solid #07d57c'
              }
              padding={'10px 25px'}
              textTransform={'capitalize'}
              bg={
                SelectService === 'unblocked'
                  ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                  : 'transparent'
              }
              width={'max-content'}
              _hover={{
                color: '#000',
                bg:
                  SelectService === 'unblocked'
                    ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                    : 'transparent',
              }}
              onClick={() => setSelectService('unblocked')}
            >
              Active
            </Button>
            <Button
              border={
                SelectService === 'blocked'
                  ? '2px solid #EAA13E'
                  : '2px solid #07d57c'
              }
              padding={'10px 25px'}
              textTransform={'capitalize'}
              bg={
                SelectService === 'blocked'
                  ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                  : 'transparent'
              }
              width={'max-content'}
              _hover={{
                color: '#000',
                bg:
                  SelectService === 'blocked'
                    ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                    : 'transparent',
              }}
              onClick={() => setSelectService('blocked')}
            >
              Blocked
            </Button>
          </Stack>
          <SimpleGrid columns={{ base: '1', md: '1', xl: '2', '2xl': '3' }}  spacing={10}>
            {selectedOrder?.map((v, k) => (
              <Stack
                gap={2}
                boxShadow="lg"
                borderRadius={'17px'}
                px={4}
                py={6}
                role="group"
                transition={'0.3s'}
                _hover={{
                  borderColor: 'primaryGreen.200',
                }}
              >
                <Stack gap={6}>
                  <Stack
                    direction={'row'}
                    gap={6}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Box display={'flex'} gap={2} alignItems={'center'}>
                      <Avatar
                        size="lg"
                        name={v?.fullName}
                        src={`${imgUrl}${v?.profile_picture}`}
                      />
                      <Box>
                        <Heading
                          fontSize={'16px'}
                          fontWeight={'700'}
                          color={'#007341'}
                          lineHeight={'1.6rem'}
                        >
                          {v?.fullName}
                        </Heading>
                        <Text
                          color={'#989BA2'}
                          fontSize={'14px'}
                          fontWeight={'400'}
                        >
                          {v?.email}
                        </Text>
                      </Box>
                    </Box>
                    <Text
                      fontSize={'17px'}
                      fontWeight={'500'}
                      textTransform={'capitalize'}
                    >
                      Role :{' '}
                      <Text as={'span'} fontWeight={'700'} color={'#007341'}>
                        {v?.role}
                      </Text>
                    </Text>
                  </Stack>
                  <Box
                    display={'flex'}
                    gap={2}
                    justifyContent={'space-between'}
                  >
                    <Button
                      as={Link}
                      to={`/dashboard/userDetails/${v._id}`}
                      border={'2px solid #07d57c'}
                      padding={'10px 25px'}
                      bg={'transparent'}
                      width={'100%'}
                      borderRadius={'10px'}
                    >
                      View Details
                    </Button>
                    {SelectService === 'blocked' ? (
                      <Button
                        border={'2px solid #E94646'}
                        padding={'10px 25px'}
                        bg={'#E94646'}
                        width={'100%'}
                        borderRadius={'10px'}
                        onClick={() => handleRemove(v?._id)}
                      >
                        Unblock
                      </Button>
                    ) : null}
                  </Box>
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
          {totalItems > itemsPerPage && (
            <Stack bg={'#fff'} px={4} py={4} borderRadius={12} mt={4}>
              <Pagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                isLoading={isLoading}
              />
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
}
