import {
  Box,
  Container,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { categories } from '../../../assets/data/Data';
import { Link } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import { baseURL, imgUrl } from '../../../utilities/config';
import { useSelector } from 'react-redux';
import { selectWebsiteData } from '../../../reducers/slices/website.slice';

export default function Categories() {
  const websiteData = useSelector(selectWebsiteData);
  const categorieslist = useMemo(() => {
    return websiteData.categories;
  }, [websiteData.categories]);
  return (
    <>
      <Stack px={{ base: '15px', md: '15px', xl: '0', '2xl': '0' }}>
        <Container
          bg={'#fff'}
          py={12}
          my={6}
          borderRadius={12}
          maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}
        >
          <Stack className="mh-2 " textAlign={'center'} mb={8}>
            <Heading as={'h2'}> Categories</Heading>
          </Stack>
          <SimpleGrid
            columns={{ base: 3, md: 5, xl: 10, '2xl': 10 }}
            spacing={6}
          >
            {categorieslist?.length > 0 &&
              categorieslist?.slice(0, 10)?.map((v, k) => {
                return (
                  <Stack
                    as={Link}
                    to={`/shop/${v?._id}`}
                    key={k}
                    borderRadius={'12px'}
                    w={'max-content'}
                    textAlign={'center'}
                    alignItems={'center'}
                  >
                    <Image
                      width={'100px'}
                      height={'100px'}
                      src={`${imgUrl}${v.url}`}
                      alt={v.name}
                    />
                    <Text
                      textTransform={'capitalize'}
                      textOverflow={'ellipsis'}
                      width={'100px'}
                      overflow={'hidden'}
                      whiteSpace={'nowrap'}
                      color={'#007341'}
                      fontWeight={700}
                      fontSize={'17px'}
                    >
                      {v.name}
                    </Text>
                  </Stack>
                );
              })}
          </SimpleGrid>
        </Container>
      </Stack>
    </>
  );
}
