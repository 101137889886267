import { Avatar, Box, Button, Heading, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { imgUrl } from '../../../utilities/config';
import moment from 'moment';

export default function StoreCard({ storelist }) {
  console.log('store new', storelist);
  return (
    <>
      {storelist?.length > 0 &&
        storelist.map((v, k) => {
          return (
            <Stack
              key={k}
              py={6}
              px={4}
              borderRadius={10}
              bg={'#FFFFFF'}
              boxShadow={'0px 16px 38px 0px rgba(0, 0, 0, 0.06)'}
            >
              <Stack
                direction={'row'}
                flexWrap={'wrap'}
                alignItems={'center'}
                justifyContent={'space-between'}
                borderBottom={'1px solid #FFFFFF'}
                paddingBottom={2}
                marginBottom={2}
                position={'relative'}
              >
                <Box display={'flex'} alignItems={'center'} gap={3}>
                  <Avatar
                    size="lg"
                    name={v?.name}
                    bgColor={'primaryGreen.100'}
                    color="#1a1a1a"
                    src={`${imgUrl}${v?.logo}`}
                  />
                  <Box className="venbox p1 m3-h">
                    <Heading
                      color={'#1B1C1D'}
                      textTransform={'capitalize'}
                      fontSize={18}
                      fontWeight={600}
                    >
                      {v?.name}
                    </Heading>
                  </Box>
                </Box>
                <Stack className="venbox p1 m3-h">
                  <Button
                    padding={{ md: '10px 15px', '2xl': '10px 20px' }}
                    variant="outline"
                    color={'#1A1B1A'}
                    border={'2px solid #1A1B1A'}
                    fontWeight={'600'}
                    fontSize={'14px'}
                    borderRadius={6}
                    _hover={{
                      color: '#000',
                    }}
                    w={'100%'}
                    textAlign={'center'}
                    as={Link}
                    to={`/dashboard/store/storesetting/${v._id}`}
                  >
                    View Store
                  </Button>
                </Stack>
              </Stack>
              <Stack className="m5-h">
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Box className="venbox p1 m3-h">
                    <Text fontSize={16} color={'#007341'} fontWeight={600}>
                      Registration Date
                    </Text>
                    <Heading
                      fontSize={16}
                      color={'#1B1C1D'}
                      fontWeight={600}
                      textTransform={'capitalize'}
                    >
                      {moment(v?.createdAt).format('DD-MMM-YYYY')}
                    </Heading>
                  </Box>
                  <Box className="venbox p1 m3-h">
                    <Text fontSize={16} color={'#007341'} fontWeight={600}>
                      Phone
                    </Text>
                    <Heading fontSize={16} color={'#1B1C1D'} fontWeight={600}>
                      {v?.phone}
                    </Heading>
                  </Box>
                  <Box className="venbox p1 m3-h">
                    <Text fontSize={16} color={'#007341'} fontWeight={600}>
                      Total Items
                    </Text>
                    <Heading fontSize={16} color={'#1B1C1D'} fontWeight={600}>
                      {v?.products}
                    </Heading>
                  </Box>
                  <Box className="venbox p1 m3-h">
                    <Text fontSize={16} color={'#007341'} fontWeight={600}>
                      Total Categories
                    </Text>
                    <Heading fontSize={16} color={'#1B1C1D'} fontWeight={600}>
                      {v?.productCategories}
                    </Heading>
                  </Box>
                </Box>
                <Box className="venbox p1 m3-h">
                  <Text fontSize={16} color={'#007341'} fontWeight={600}>
                    Location
                  </Text>
                  <Heading
                    noOfLines={1}
                    fontSize={16}
                    color={'#1B1C1D'}
                    fontWeight={600}
                  >
                    {v?.address}
                  </Heading>
                </Box>
              </Stack>
            </Stack>
          );
        })}
    </>
  );
}
