import React, { useEffect, useState } from 'react';
import InquiriesUserDetails from '../../../components/Dashboard/Contact/InquiriesUserDetails';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

export default function InquiriesDetails() {
  // const { id } = useParams();
  return (
    <Stack overflowY="hidden">
      <MainDashboard>
        <InquiriesUserDetails />
      </MainDashboard>
    </Stack>
  );
}
