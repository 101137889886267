import React, { useEffect, useState } from 'react';
import {
  Stack,
  Box,
  Text,
  Heading,
  HStack,
  FormLabel,
  PinInput,
  PinInputField,
  Button,
  useToast,
  Container
} from '@chakra-ui/react';
 
import { POST } from '../../../utilities/ApiProvider';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


export default function VerificationForm() {

  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const user = useSelector(state => state.value);
  useEffect(() => {
    (async () => {
      let localEmail = localStorage.getItem('forgotemail');

      setEmail(localEmail);
      console.log('email', email);
    })();
  }, []);

  const verifyCode = async () => {
    setIsLoading(true);
    try {
      if (code === '') {
        toast({
          description: 'Code is required!',
          duration: 2000,
          position: 'top-right',
          status: 'error',
        });
      } else {
        let res = await POST(`/users/verifyOtp`, {
          email,
          otpCode: Number(code),
        });
        console.log('verification', res);
        if (res.status === 'success') {
          toast({
            description: res.message,
            duration: 2000,
            position: 'top-right',
            status: res.status,
          });
          navigate('/newpassword');
        } else {
          toast({
            description: res.message,
            duration: 2000,
            position: 'top-right',
            status: 'error',
          });
        }
      }
    } catch (err) {
      toast({
        description: err.message,
        duration: 2000,
        position: 'top-right',
        status: 'error',
      });
    }
    setIsLoading(false);
  };


  return (
    <>
       <Stack px={{ base: '15px', md: '15px', xl: '0', '2xl': '0' }}>
      <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
        <Stack
          justifyItems={'center'}
          alignItems={'center'}
          px={8}
          bg={'#fff'}
          py={12}
          borderRadius={12}
          gap={8}
        >
          <Heading
            color={'#111'}
            fontSize={'25px'}
            fontWeight={700}
            textAlign={'center'}
          >
            Forgot Password
          </Heading>
          <Stack className="regwarp" gap={4}>
          <Stack mb={4}>
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Enter Code
            </FormLabel>
            <Box className="pfiled">
              <HStack>
                <PinInput
                  value={code}
                  onChange={e => setCode(e)}
                  placeholder="-"
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </Box>
          </Stack>

          <Stack direction={'row'}>
            <Button
              onClick={verifyCode}
              isLoading={isLoading}
              padding={'10px 50px'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
            >
              Continue
            </Button>
          </Stack>
        </Stack>
        </Stack>
      </Container>
    </Stack>
    </>
  )
}
