import React from 'react'
import Header from '../../components/Website/Header'
import MiniBanner from '../../components/Website/banner/MiniBanner'
import Footer from '../../components/Website/Footer'
import Forgot from '../../components/Website/Auth/Forgot'
 

export default function ForgotPassword() {
  return (
    <>
     <Header/>
      <MiniBanner title={'Forgot Password'} breadtitle={'Forgot Password'} />
     <Forgot/>
      <Footer/>
    </>
  )
}
