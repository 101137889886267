import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import star from '../../../assets/images/website/star.svg';
import { FiShoppingCart } from 'react-icons/fi';
import { arrproducts } from '../../../assets/data/Data';
import { Link, useNavigate } from 'react-router-dom';
import { FaRegHeart, FaHeart } from 'react-icons/fa';
import { baseURL, imgUrl } from '../../../utilities/config';
import { GET, POST } from '../../../utilities/ApiProvider';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectWebsiteData,
  addWishlist,
  setWebsiteData,
} from '../../../reducers/slices/website.slice';
import { addToCarts } from '../../../reducers/slices/cart.slice';
import {
  convertNumbertoCommaSeperate,
  useAddToCart,
  useAddToWhishlist,
} from '../../../utilities/helper';
import Pagination from '../../../utilities/Pagination';
import ReactStars from 'react-rating-stars-component';

export default function Arrivals() {
  const websiteData = useSelector(selectWebsiteData);
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleFavourite, loading } = useAddToWhishlist();
  const arrproductlist = useMemo(() => {
    return websiteData.arrproducts;
  }, [websiteData.arrproducts]);
  const user = useSelector(state => state.user.value);

  const { addToCart, loading: isLoading } = useAddToCart();
  console.log(isLoading, 'checking');
  return (
    <>
      <Stack px={{ base: '15px', md: '15px', xl: '0', '2xl': '0' }}>
        <Container
          bg={'#fff'}
          p={0}
          my={6}
          borderRadius={12}
          maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}
        >
          <Stack alignItems={'center'}>
            <Stack className="mh-2 " py={8} textAlign={'center'}>
              <Heading as={'h2'}>New Arrivals</Heading>
            </Stack>
          </Stack>

          <SimpleGrid
            columns={{ base: '2', sm: '3', lg: '4', xl: '5', '2xl': '5' }}
            spacing={0}
          >
            {arrproductlist?.length > 0 &&
              arrproductlist.map((v, k) => {
                return (
                  <Stack key={k} border={'1px solid #D9E8D6'}>
                    <Stack as={Link} to={`/productdetail/${v._id}`}>
                      <Stack position={'relative'}>
                        {((v.off && v.off > 0) ||
                          (v.stock && v.stock !== 0)) && (
                          <Box
                            display={'flex'}
                            gap={2}
                            position={'absolute'}
                            top={'5px'}
                            left={'5px'}
                          >
                            {v.off > 0 && (
                              <Tag
                                bg={'#007341'}
                                lineHeight={'base'}
                                color={'#fff'}
                                fontSize={'12px'}
                                fontWeight={600}
                              >
                                {v.off} %
                              </Tag>
                            )}
                            {v.stock && (
                              <Tag
                                bg={v.stock > 0 ? '#E0883B' : '#C5262F'}
                                lineHeight={'base'}
                                color={'#fff'}
                                fontSize={'12px'}
                                fontWeight={600}
                              >
                                {v.stock > 0 ? 'In Stock' : 'Out of Stock'}
                              </Tag>
                            )}
                          </Box>
                        )}
                        <Image
                          minHeight={'238px'}
                          maxHeight={'238px'}
                          objectFit={'contain'}
                          src={
                            v?.url?.includes('https')
                              ? v?.url
                              : `${imgUrl}${v?.url}`
                          }
                        />
                      </Stack>
                      <Stack className="mh-3" px={4} py={3} spacing={2}>
                        <Stack>
                          <Heading
                            as={'h3'}
                            noOfLines={1}
                            color={'#212121'}
                            display={'-webkit-box'}
                            WebkitLineClamp={2}
                            WebkitBoxOrient={'vertical'}
                          >
                            {v.name}
                          </Heading>
                          <Text
                            fontSize={'13px'}
                            minHeight={'40px'}
                            noOfLines={[1, 2]}
                            color={'#212121'}
                            overflow={'hidden'}
                            display={'-webkit-box'}
                            WebkitLineClamp={2}
                            WebkitBoxOrient={'vertical'}
                          >
                            {v.description}
                          </Text>
                        </Stack>
                        <Box display={'flex'} gap={2}>
                          <ReactStars
                            count={5}
                            value={v.rating}
                            size={15}
                            isHalf={true}
                            edit={false}
                            activeColor="#ffd700"
                          />

                          <Text fontSize={'12px'} color={'#989BA2'}>
                            ({v.rating})
                          </Text>
                        </Box>
                      </Stack>
                    </Stack>
                    <Stack px={4} pb={4} spacing={2}>
                      <Text
                        fontSize={'13px'}
                        minHeight={'40px'}
                        noOfLines={[1, 2]}
                        color={'#212121'}
                      >
                        {v.tagName}
                      </Text>
                      <Box
                        className="mh-3"
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <Stack>
                          {v?.isDiscount ? (
                            <Heading as={'h3'}>
                              Rs{' '}
                              {convertNumbertoCommaSeperate(v.discountedPrice)}
                              <Text
                                as="span"
                                ml={2}
                                textDecoration="line-through"
                                color="gray.500"
                                fontSize={'sm'}
                              >
                                Rs {convertNumbertoCommaSeperate(v.price)}
                              </Text>
                            </Heading>
                          ) : (
                            <Heading as={'h3'}>
                              Rs {convertNumbertoCommaSeperate(v.price)}
                            </Heading>
                          )}
                        </Stack>
                        <Flex gap={2}>
                          {user && (
                            <Button
                              disabled={loading}
                              onClick={() => {
                                handleFavourite(v, false);
                              }}
                              w={'40px'}
                              height={'40px'}
                              borderRadius={'50%'}
                            >
                              <Icon
                                fontSize={'15px'}
                                color={'#007341'}
                                as={v?.is_favourite ? FaHeart : FaRegHeart}
                              />
                            </Button>
                          )}
                          <Button
                            disabled={v.stock <= 0 || isLoading ? true : false}
                            onClick={() => addToCart(v)}
                            w={'40px'}
                            height={'40px'}
                            borderRadius={'50%'}
                          >
                            <Icon
                              fontSize={'15px'}
                              color={'#007341'}
                              as={FiShoppingCart}
                            />
                          </Button>
                        </Flex>
                      </Box>
                    </Stack>
                  </Stack>
                );
              })}
          </SimpleGrid>
          <Stack py={4} textAlign={'center'}>
            <Button
              color={'#243F2F'}
              border={'1px solid #243F2F'}
              borderRadius={'25px'}
              cursor={'pointer'}
              as={Link}
              margin={'auto'}
              to={'/new-arrivals'}
              fontWeight={'700'}
              fontSize={{ base: '14px', '2xl': '16px' }}
              w={'max-content'}
              p={{ base: '10px 16px', '2xl': '10px 20px' }}
            >
              Show All
            </Button>
          </Stack>
        </Container>
      </Stack>
    </>
  );
}
