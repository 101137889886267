import React, { useEffect, useMemo, useState } from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Container,
  Stack,
  Box,
  Image,
  Heading,
  Text,
  SimpleGrid,
  Button,
  Icon,
  Flex,
  Tag,
  useToast,
} from '@chakra-ui/react';
import product1 from '../../../assets/images/website/product1.jpg';
import star from '../../../assets/images/website/star.svg';
import { FiShoppingCart } from 'react-icons/fi';
import { products } from '../../../assets/data/Data';
import { Link } from 'react-router-dom';
import { FaRegHeart, FaHeart } from 'react-icons/fa';
import { GET, POST } from '../../../utilities/ApiProvider';
import { baseURL, imgUrl } from '../../../utilities/config';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectWebsiteData,
  addWishlist,
  setWebsiteData,
  selectWishlistData,
} from '../../../reducers/slices/website.slice';
import { selectCartData } from '../../../reducers/slices/cart.slice';
import { addToCarts } from '../../../reducers/slices/cart.slice';
import {
  convertNumbertoCommaSeperate,
  useAddToCart,
  useAddToWhishlist,
} from '../../../utilities/helper';
import ReactStars from 'react-rating-stars-component';

export default function TopRated() {
  const [selectedTab, setSelectedTab] = useState('topRated');
  const websiteData = useSelector(selectWebsiteData);
  const cartData = useSelector(selectCartData);
  const wishlist = useSelector(selectWishlistData);
  const toast = useToast();
  const dispatch = useDispatch();
  const [productlist, setProductlist] = useState([]);
  const productlists = useMemo(() => {
    setProductlist(websiteData.products);
    return websiteData.products;
  }, [websiteData.products]);

  const user = useSelector(state => state.user.value);
  const { handleFavourite, loading } = useAddToWhishlist();

  const { addToCart, loading: isLoading } = useAddToCart();
  // useEffect(() => {
  //   let temp = productlist['topRated'];
  //   let temp2 = productlist['bestSeller'];

  //   const updatedList = temp.map(item => {
  //     if (wishlist.find(v => v._id === item._id)) {
  //       return { ...item, is_favourite: true };
  //     }
  //     return { ...item, is_favourite: false };
  //   });
  //   const updatedList1 = temp2.map(item => {
  //     if (wishlist.find(v => v._id === item._id)) {
  //       return { ...item, is_favourite: true };
  //     }
  //     return { ...item, is_favourite: false };
  //   });
  //   if (loading) return;
  //   setProductlist({
  //     ...productlist,
  //     ['topRated']: updatedList,
  //     ['bestSeller']: updatedList1,
  //   });
  // }, []);

  return (
    <>
      <Stack px={{ base: '15px', md: '15px', xl: '0', '2xl': '0' }}>
        <Container
          bg={'#fff'}
          pt={12}
          mb={6}
          px={0}
          borderRadius={12}
          maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}
        >
          <Tabs variant="unstyled">
            <TabList justifyContent={'center'} alignItems={'center'} mb={8}>
              {productlist && productlist?.['topRated']?.length > 0 && (
                <Tab
                  fontSize={'22px'}
                  fontWeight={'700'}
                  borderBottom={'1px solid #fff'}
                  onClick={() => setSelectedTab('topRated')}
                  _selected={{
                    fontSize: '22px',
                    fontWeight: '700',
                    bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
                    backgroundClip: 'text',
                    borderBottom: '2px solid #E28E3C',
                  }}
                >
                  Top Rated
                </Tab>
              )}
              {productlist && productlist?.['bestSeller']?.length > 0 && (
                <Tab
                  fontSize={'22px'}
                  fontWeight={'700'}
                  onClick={() => setSelectedTab('bestSeller')}
                  borderBottom={'1px solid #fff'}
                  _selected={{
                    fontSize: '22px',
                    fontWeight: '700',
                    bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
                    backgroundClip: 'text',
                    borderBottom: '2px solid #E28E3C',
                  }}
                >
                  Best Seller
                </Tab>
              )}
            </TabList>
            <TabPanels>
              {productlist &&
                Object?.keys(productlist)?.map(item => {
                  return (
                    <TabPanel p={0}>
                      <SimpleGrid
                        columns={{
                          base: '2',
                          sm: '3',
                          lg: '4',
                          xl: '5',
                          '2xl': '5',
                        }}
                        spacing={0}
                      >
                        {productlist?.[selectedTab]?.length > 0 &&
                          productlist?.[selectedTab]?.map((v, k) => {
                            return (
                              <Stack key={k} border={'1px solid #D9E8D6'}>
                                <Stack as={Link} to={`/productdetail/${v._id}`}>
                                  <Stack position={'relative'}>
                                    {((v.off && v.off > 0) ||
                                      (v.stock && v.stock !== 0)) && (
                                      <Box
                                        display={'flex'}
                                        gap={2}
                                        position={'absolute'}
                                        top={'5px'}
                                        left={'5px'}
                                      >
                                        {v.off > 0 && (
                                          <Tag
                                            bg={'#007341'}
                                            lineHeight={'base'}
                                            color={'#fff'}
                                            fontSize={'12px'}
                                            fontWeight={600}
                                          >
                                            {v.off} %
                                          </Tag>
                                        )}
                                        {v.stock && (
                                          <Tag
                                            bg={
                                              v.stock > 0
                                                ? '#E0883B'
                                                : '#C5262F'
                                            }
                                            lineHeight={'base'}
                                            color={'#fff'}
                                            fontSize={'12px'}
                                            fontWeight={600}
                                          >
                                            {v.stock > 0
                                              ? 'In Stock'
                                              : 'Out of Stock'}
                                          </Tag>
                                        )}
                                      </Box>
                                    )}
                                    <Image
                                      minHeight={'238px'}
                                      maxHeight={'238px'}
                                      objectFit={'contain'}
                                      src={
                                        v?.url.includes('https')
                                          ? v?.url
                                          : `${imgUrl}${v?.url}`
                                      }
                                    />
                                  </Stack>

                                  <Stack
                                    className="mh-3"
                                    px={4}
                                    py={3}
                                    spacing={2}
                                  >
                                    <Stack>
                                      <Heading
                                        noOfLines={1}
                                        as={'h3'}
                                        color={'#212121'}
                                        overflow={'hidden'}
                                        display={'-webkit-box'}
                                        WebkitLineClamp={2}
                                        WebkitBoxOrient={'vertical'}
                                      >
                                        {v.name}
                                      </Heading>
                                      <Text
                                        minHeight={'40px'}
                                        fontSize={'13px'}
                                        color={'#212121'}
                                        overflow={'hidden'}
                                        display={'-webkit-box'}
                                        WebkitLineClamp={2}
                                        WebkitBoxOrient={'vertical'}
                                        noOfLines={[1, 2]}
                                      >
                                        {v.description}
                                      </Text>
                                    </Stack>
                                    <Box display={'flex'} gap={2}>
                                      <ReactStars
                                        count={5}
                                        value={v.rating}
                                        size={15}
                                        isHalf={true}
                                        edit={false}
                                        activeColor="#ffd700"
                                      />
                                      <Text fontSize={'12px'} color={'#989BA2'}>
                                        ({v.rating})
                                      </Text>
                                    </Box>
                                  </Stack>
                                </Stack>
                                <Stack px={4} pb={4} spacing={2}>
                                  <Text
                                    fontSize={'13px'}
                                    minHeight={'40px'}
                                    noOfLines={[1, 2]}
                                    color={'#212121'}
                                  >
                                    {v.tagName}
                                  </Text>
                                  <Box
                                    className="mh-3"
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                  >
                                    <Stack>
                                      {v?.isDiscount ? (
                                        <Heading as={'h3'}>
                                          Rs{' '}
                                          {convertNumbertoCommaSeperate(
                                            v.discountedPrice
                                          )}
                                          <Text
                                            as="span"
                                            ml={2}
                                            textDecoration="line-through"
                                            color="gray.500"
                                            fontSize={'sm'}
                                          >
                                            Rs{' '}
                                            {convertNumbertoCommaSeperate(
                                              v.price
                                            )}
                                          </Text>
                                        </Heading>
                                      ) : (
                                        <Heading as={'h3'}>
                                          Rs{' '}
                                          {convertNumbertoCommaSeperate(
                                            v.price
                                          )}
                                        </Heading>
                                      )}
                                    </Stack>

                                    <Flex gap={2}>
                                      {user && (
                                        <Button
                                          disabled={loading}
                                          onClick={() => {
                                            handleFavourite(
                                              v,
                                              true,
                                              selectedTab
                                            );
                                            // let temp = productlist[
                                            //   selectedTab
                                            // ].map(item => ({
                                            //   ...item,
                                            // }));

                                            // let check = temp.find(
                                            //   item => item._id === v._id
                                            // )?.is_favourite;

                                            // temp = temp.map(item => {
                                            //   if (item._id === v._id) {
                                            //     return {
                                            //       ...item,
                                            //       is_favourite: !check,
                                            //     };
                                            //   }
                                            //   return item;
                                            // });
                                            // if (loading) return;
                                            // setProductlist(temp);
                                          }}
                                          w={'40px'}
                                          height={'40px'}
                                          borderRadius={'50%'}
                                        >
                                          <Icon
                                            fontSize={'15px'}
                                            color={'#007341'}
                                            as={
                                              v?.is_favourite
                                                ? FaHeart
                                                : FaRegHeart
                                            }
                                          />
                                        </Button>
                                      )}
                                      <Button
                                        disabled={
                                          v.stock <= 0 || isLoading
                                            ? true
                                            : false
                                        }
                                        onClick={() => addToCart(v)}
                                        w={'40px'}
                                        height={'40px'}
                                        borderRadius={'50%'}
                                      >
                                        <Icon
                                          fontSize={'15px'}
                                          color={'#007341'}
                                          as={FiShoppingCart}
                                        />
                                      </Button>
                                    </Flex>
                                  </Box>
                                </Stack>
                              </Stack>
                            );
                          })}
                      </SimpleGrid>
                    </TabPanel>
                  );
                })}
            </TabPanels>
          </Tabs>
          {(productlist && productlist?.['topRated']?.length > 0) ||
            (productlist?.['bestSeller']?.length > 0 && (
              <Stack py={4} textAlign={'center'}>
                <Button
                  color={'#243F2F'}
                  border={'1px solid #243F2F'}
                  borderRadius={'25px'}
                  cursor={'pointer'}
                  as={Link}
                  margin={'auto'}
                  to={`/allproducts?type=${selectedTab}`}
                  fontWeight={'700'}
                  fontSize={{ base: '14px', '2xl': '16px' }}
                  w={'max-content'}
                  p={{ base: '10px 16px', '2xl': '10px 20px' }}
                >
                  Show All
                </Button>
              </Stack>
            ))}
        </Container>
      </Stack>
    </>
  );
}
