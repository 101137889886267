// rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import websiteReducer from './slices/website.slice';
import userReducer from './useReducers';
import cartReducer from './slices/cart.slice';

const rootReducer = combineReducers({
  website: websiteReducer,
  user: userReducer,
  cart: cartReducer,
});

export default rootReducer;
