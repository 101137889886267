import React, { useState } from 'react';
import { Box, IconButton, HStack, Icon } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { FaStar } from 'react-icons/fa';

const Rating = ({ max = 5, size = 'md', defaultValue = 0, onChange }) => {
  const [rating, setRating] = useState(defaultValue);
  const [hover, setHover] = useState(-1);

  const handleClick = index => {
    setRating(index);
    if (onChange) {
      onChange(index);
    }
  };

  return (
    <HStack spacing={1}>
      {Array.from({ length: max }, (_, index) => (
        <Icon
          cursor={'pointer'}
          key={index}
          as={FaStar}
          color={
            index < (hover !== -1 ? hover : rating) ? 'yellow.500' : 'gray.300'
          }
          variant={
            index < (hover !== -1 ? hover : rating) ? 'solid' : 'outline'
          }
          onClick={() => handleClick(index + 1)}
          onMouseEnter={() => setHover(index + 1)}
          onMouseLeave={() => setHover(-1)}
          aria-label={`Rate ${index + 1}`}
        />
      ))}
    </HStack>
  );
};

export default Rating;
