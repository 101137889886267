import {
  Stack,
  Heading,
  Icon,
  Text,
  Box,
  Button,
  Image,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  useToast,
  Container,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { LuUser2,LuPhone } from "react-icons/lu";
import { AiOutlineLock } from 'react-icons/ai';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import defaultPic from '../../../assets/images/defaultPic.svg';
import { GoUpload,GoMail } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { POST, PUT } from '../../../utilities/ApiProvider.js';

import { loadAdminPic, loadUser, updateName } from '../../../reducers/useReducers.js';
import { imgUrl } from '../../../utilities/config.js';
export default function UserAcountDetails() {
  const toast = useToast();
  const imgReg = useRef(null);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showpass, setshowpass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [username, setUsername] = useState({
    fullName: '',
    phone: '',
  });
  const [pass, setPass] = useState({
    password: '',
    confirmPassword: '',
  });
  const user = useSelector(state => state.user.value);
  useEffect(() => {
    console.log('user', user);
  }, [user]);

  const updateUser = async () => {
    setIsLoading(true);
    try {
      let res = await PUT(`/users/updateUser`, username, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (res.status === 'success') {
        localStorage.setItem('ajwaUser', JSON.stringify(res.data));
        console.log(res.data);
        dispatch(loadUser(res?.data));
         
      } else {
        toast({
          description: res.message,
          status: res.status,
          position: 'top-right',
          duration: 3000,
        });
      }
      console.log('add generl responce ', res);
      toast({
        description: res.message,
        status: res.status,
        position: 'top-right',
        duration: 3000,
      });
    } catch (err) {
      toast({
        description: err.message,
        status: 'success',
        position: 'top-right',
        duration: 1000,
      });
    }
    setIsLoading(false);
  };

  const uploadProfilePic = async () => {
    setUploadingImage(true);
    try {
      let form = document.getElementById('profilePicForm');
      let data = new FormData(form);
      let res = await PUT('/users/updateUser', data, {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log('upload pic ', res);
      dispatch(loadAdminPic(res?.data?.profile_picture));
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500,
      });
    }
    setUploadingImage(false);
  };

  const updatePass = async () => {
    setIsLoading(true);
    try {
      let res = await PUT(`/users/newPassword`, pass, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log('update pass ', res);
      toast({
        description: res.message,
        status: res.status,
        position: 'top-right',
        duration: 3000,
      });
      onClose();
    } catch (err) {
      toast({
        description: err.message,
        status: 'success',
        position: 'top-right',
        duration: 1000,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setUsername({
      fullName: user?.fullName,
      phone: user?.phone,
    });
  }, [user]);

  return (
    <>
      <Stack>
        <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
          <Stack px={12} bg={'#fff'} py={12} borderRadius={12} gap={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack>
                <Heading fontSize={'25px'} fontWeight={700} color={'#111'}>
                  Account Details
                </Heading>
              </Stack>
              <Stack className="btn">
                <Button
                  onClick={onOpen}
                  bg={
                    'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                  }
                  fontSize={{ base: '12px', xl: '14px' }}
                  fontWeight={500}
                  color={'#fff'}
                  width={'max-content'}
                  px={'40px'}
                  py={'24px'}
                  gap={2}
                  transition={'.3s'}
                  h={{ base: '35px', xl: '45px' }}
                  _hover={{
                    boxShadow: '0px 7px 20px 0px #0000003b',
                  }}
                >
                  <Text as={'span'}>Change Password</Text>
                </Button>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" gap={6}>
              <Box
                 
                w="138px"
                h="138px"
                borderRadius="100%"
                border={'4px solid'}
                borderColor="#E0883B"
                overflow="hidden"
              >
                <Image
                  src={`${imgUrl}${user?.profile_picture}` ?? defaultPic}
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              </Box>
              <Box>
                <form id="profilePicForm">
                  <Input
                    onChange={uploadProfilePic}
                    ref={imgReg}
                    display={'none'}
                    type="file"
                    name="profile_picture"
                  />
                </form>
                <Stack className="btn" mb="20px">
                  <Button
                    isLoading={uploadingImage}
                    onClick={() => imgReg?.current.click()}
                    bg={
                      'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                    }
                    fontSize={{ base: '12px', xl: '14px' }}
                    fontWeight={500}
                    color={'#fff'}
                    width={'max-content'}
                    px={'40px'}
                    py={'24px'}
                    gap={2}
                    transition={'.3s'}
                    h={{ base: '35px', xl: '45px' }}
                    _hover={{
                      boxShadow: '0px 7px 20px 0px #0000003b',
                    }}
                  >
                    <Text as={'span'}>Upload Photo</Text>
                  </Button>
                </Stack>
              </Box>
            </Stack>
            <Stack gap={4}>
              <Stack
                maxW="85%"
                direction="row"
                flexWrap="wrap"
                spacing="0"
                alignItems="flex-end"
              >
                <Box w="48%">
                  <FormLabel
                    fontWeight={500}
                    m={0}
                    color={'#111'}
                    fontSize={'14px'}
                  >
                    Full Name
                  </FormLabel>
                  <Box className="Ifiled">
                  <Icon fontSize={'25px'} color={'#949494'} as={LuUser2} />
                    <Input
                      type="text"
                      value={username?.fullName}
                      onChange={e =>
                        setUsername({ ...username, fullName: e.target.value })
                      }
                    />
                  </Box>
                </Box>
                <Box w="48%" ml="20px !important">
                  <FormLabel
                      fontWeight={500}
                      m={0}
                      color={'#111'}
                      fontSize={'14px'}
                  >
                    Email Address
                  </FormLabel>
                  <Box className="Ifiled">
                  <Icon fontSize={'25px'} color={'#949494'} as={GoMail} />
                    <Input
                      value={user?.email}
                      readOnly
                      cursor={'not-allowed'}
                    />
                  </Box>
                </Box>
                <Box w="48%" mt="30px !important">
                  <FormLabel
                        fontWeight={500}
                        m={0}
                        color={'#111'}
                        fontSize={'14px'}
                  >
                    Phone Number
                  </FormLabel>
                  <Box className="Ifiled">
                  <Icon fontSize={'25px'} color={'#949494'} as={LuPhone} />
                    <Input
                      value={username?.phone}
                      onChange={e =>
                        setUsername({ ...username, phone: e.target.value })
                      }
                      type="number"
                    />
                  </Box>
                </Box>
               
              </Stack>
              <Box  >
                  <Stack className="btn">
                    <Button
                      isLoading={isLoading}
                      onClick={updateUser}
                      bg={
                        'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                      }
                      fontSize={{ base: '12px', xl: '14px' }}
                      fontWeight={500}
                      color={'#fff'}
                      width={'max-content'}
                      px={'40px'}
                      py={'24px'}
                      gap={2}
                      transition={'.3s'}
                      h={{ base: '35px', xl: '45px' }}
                      _hover={{
                        boxShadow: '0px 7px 20px 0px #0000003b',
                      }}
                    >
                      <Text as={'span'}>Save Changes</Text>
                    </Button>
                  </Stack>
                </Box>
            </Stack>
            <Modal isOpen={isOpen} onClose={onClose} size="2xl">
              <ModalOverlay />
              <ModalContent
                bgSize="cover"
               
                bgPos="center"
                p="20px 40px"
              >
                <ModalHeader
                  fontWeight="600"
                  fontSize={'24px'}
                  color={'#111'}
                  textAlign="center"
                >
                  Change Password
                </ModalHeader>
                <ModalBody>
                  <FormLabel
                    color={'#111'}
                    mt="20px !important"
                    fontWeight="600"
                  >
                    New Password
                  </FormLabel>
                  <Box className="Ifiled">
                    <Icon
                      fontSize={'25px'}
                      color={'#2BAF59'}
                      as={AiOutlineLock}
                    />
                    <Input
                      type={showpass ? 'text' : 'password'}
                      placeholder="Enter here"
                      value={pass.password}
                      onChange={e =>
                        setPass({
                          ...pass,
                          password: e.target.value,
                        })
                      }
                    />
                    <Button onClick={() => setshowpass(!showpass)}>
                      <Icon as={showpass ? BsEye : BsEyeSlash} />
                    </Button>
                  </Box>
                  <FormLabel
                    mt="20px !important"
                    color={'#111'}
                    fontWeight="600"
                  >
                    Re-type Password
                  </FormLabel>
                  <Box className="Ifiled">
                    <Icon
                      fontSize={'25px'}
                      color={'#2BAF59'}
                      as={AiOutlineLock}
                    />
                    <Input
                      type={showpass ? 'text' : 'password'}
                      placeholder="Enter here"
                      value={pass.confirmPassword}
                      onChange={e =>
                        setPass({
                          ...pass,
                          confirmPassword: e.target.value,
                        })
                      }
                    />
                    <Button onClick={() => setshowpass(!showpass)}>
                      <Icon as={showpass ? BsEye : BsEyeSlash} />
                    </Button>
                  </Box>
                </ModalBody>
                <ModalFooter justifyContent="center">
                  <Stack className="btn">
                    <Button
                      onClick={updatePass}
                      isLoading={isLoading}
                      bg={
                        'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                      }
                      fontSize={{ base: '12px', xl: '14px' }}
                      fontWeight={500}
                      color={'#fff'}
                      width={'max-content'}
                      px={'40px'}
                      py={'24px'}
                      gap={2}
                      transition={'.3s'}
                      h={{ base: '35px', xl: '45px' }}
                      _hover={{
                        boxShadow: '0px 7px 20px 0px #0000003b',
                      }}
                    >
                      <Text as={'span'}>Save</Text>
                    </Button>
                  </Stack>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Stack>
        </Container>
      </Stack>
    </>
  );
}
