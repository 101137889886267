import React, { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  Text,
  Checkbox,
} from '@chakra-ui/react';
import { GET } from '../../../utilities/ApiProvider';

function Permission({ setData, abc }) {
  const [permission, setPermission] = useState([]);
  const [Npermission, setNPermission] = useState([]);

  async function getPermission() {
    const response = await GET(`/roles/permissions`);
    if (response.status === 200) {
      setPermission(response.data);
      if (abc) {
        setNPermission(abc);
      }
    } else {
      console.log(response);
      setPermission([]);
    }
  }

  useEffect(() => {
    getPermission();
  }, []);

  const handleChange = (e, item) => {
    if (e.target.checked) {
      setNPermission(prev => [...prev, item]);
      setData(prevData => [...prevData, item._id]);
    } else {
      setNPermission(prev => prev.filter(p => p._id !== item._id));
      setData(prevData => prevData.filter(id => id !== item._id));
    }
  };

  return (
    <Stack display={'flex'} flexDirection={'column'} gap={3}>
      <Text fontSize={'18px'} fontWeight={500}>
        Permission Settings
      </Text>
      <Stack
        display={'flex'}
        flexDirection={'row'}
        flexWrap={'wrap'}
        gap={4}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {permission.map(item => (
          <Box
            key={item._id}
            w="30%"
            h="62px"
            border={'1px solid #B4B4B4'}
            borderRadius={'8px'}
            background={'#F3F3F3'}
          >
            <Stack direction={'row'} justifyContent={'space-between'} p={4}>
              <Text color={'#007341'} fontSize={'18px'} fontWeight={500}>
                {item.module}
              </Text>
              <Checkbox
                isChecked={Npermission.some(p => p._id === item._id)}
                onChange={e => handleChange(e, item)}
                colorScheme="green"
              />
            </Stack>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}

export default Permission;
