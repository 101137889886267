import { Container, Stack, Box, useBreakpointValue } from '@chakra-ui/react';
import React, { useMemo, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination } from 'swiper/modules';
import { useSelector } from 'react-redux';
import { imgUrl } from '../../../utilities/config';
import { selectWebsiteData } from '../../../reducers/slices/website.slice';
import bannerBg from '../../../assets/images/website/banner.jpg';

export default function Banner() {
  const websiteData = useSelector(selectWebsiteData);
  const banners = useMemo(
    () => websiteData?.banners?.webBanner,
    [websiteData.banners]
  );
  const Mobbanners = useMemo(
    () => websiteData?.banners?.mobileBanner,
    [websiteData.banners]
  );

  const isMobile = useBreakpointValue({ base: true, md: false });

  const swiperRef = useRef(null);
  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <Stack mt={4} px={{ base: '10px', '2xl': '0' }}>
      <Container p={0} maxW={{ base: '1366px', xl: '6xl', '2xl': '1440px' }}>
        {isMobile ? (
          <Swiper
            ref={swiperRef}
            slidesPerView={1}
            spaceBetween={'20px'}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            loop={true}
            pagination={{ clickable: true }}
            breakpoints={{
              375: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
            }}
          >
            {Mobbanners && Mobbanners.length > 0 ? (
              Mobbanners.map(item => (
                <SwiperSlide key={item?._id} className="mobileView">
                  <Box
                    key={item?._id}
                    bg={'#EFF5EE'}
                    bgImage={`${imgUrl}${item?.url}`}
                    borderRadius={12}
                    bgPosition={'center'}
                    bgRepeat={'no-repeat'}
                    bgSize={'contain'}
                    height={{ base: '160px', xl: '590px', '2xl': '590px' }}
                  ></Box>
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide className="mobileView">
                <Box
                  bg={'#EFF5EE'}
                  bgImage={bannerBg}
                  bgPosition={'center'}
                  bgRepeat={'no-repeat'}
                  bgSize={'cover'}
                  height={{ base: '240px', xl: '590px', '2xl': '590px' }}
                ></Box>
              </SwiperSlide>
            )}
          </Swiper>
        ) : (
          <Swiper
            ref={swiperRef}
            slidesPerView={1}
            spaceBetween={'20px'}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Autoplay]}
            loop={true}
            pagination={{ clickable: true }}

            // spaceBetween={50}
            // slidesPerView={1}
            // breakpoints={{
            //   1024: {
            //     slidesPerView: 1,
            //     spaceBetween: 0,
            //   },
            //   1366: {
            //     slidesPerView: 1,
            //     spaceBetween: 0,
            //   },
            // }}
          >
            {banners && banners.length > 0 ? (
              banners.map(item => (
                <SwiperSlide key={item?._id} className="desktopView">
                  <Box
                    key={item?._id}
                    bg={'#EFF5EE'}
                    bgImage={`${imgUrl}/${item?.url}`}
                    borderRadius={12}
                    bgPosition={'center'}
                    bgRepeat={'no-repeat'}
                    bgSize={'cover'}
                    height={{
                      base: '176px',
                      sm: '320px',
                      lg: '450px',
                      xl: '500px',
                      '2xl': '590px',
                    }}
                  ></Box>
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide className="desktopView">
                <Box
                  bg={'#EFF5EE'}
                  bgImage={bannerBg}
                  bgPosition={'center'}
                  bgRepeat={'no-repeat'}
                  bgSize={'cover'}
                  height={{ base: '240px', xl: '280px', '2xl': '590px' }}
                ></Box>
              </SwiperSlide>
            )}
          </Swiper>
        )}
      </Container>
    </Stack>
  );
}
